import spacetime from 'spacetime';
import { ParsableDate } from 'spacetime/types/types';

export const fosdickHolidays = [
  // Think of months as indexed at 0, e.g. 1 = February
  new Date(2020, 10, 26),
  new Date(2020, 11, 25, 1),
  new Date(2021, 0, 1),
  /* Other 2021 dates:

  May 26 - June 1st: ERP migration
  May 31st
  July 5th
  September 6th
  November 25th
  December 24th
  December 31st

   */

  new Date(2021, 4, 26),
  new Date(2021, 4, 27),
  new Date(2021, 4, 28),
  new Date(2021, 4, 31),
  new Date(2021, 5, 1),
  new Date(2021, 6, 5),

  new Date(2021, 8, 6),
  new Date(2021, 10, 25),
  new Date(2021, 11, 24),
  new Date(2021, 11, 31)
];

export const blackoutDays = [new Date(2023, 9, 23)];

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const disabledDaysForDayPicker: Array<
  ParsableDate | { before: Date } | { daysOfWeek: number[] }
> = [...fosdickHolidays, ...blackoutDays, { before: tomorrow }, { daysOfWeek: [0, 6] }];

/* Spacetime variables and functions */

spacetime.extend({
  isFosdickHoliday() {
    return !!fosdickHolidays.find((holiday) => {
      const holidayAsSpacetime = spacetime(holiday) as {
        year: () => ParsableDate;
        month: () => ParsableDate;
        date: () => ParsableDate;
      };
      return (
        // @ts-ignore
        this.year() === holidayAsSpacetime.year() &&
        // @ts-ignore
        this.month() === holidayAsSpacetime.month() &&
        // @ts-ignore
        this.date() === holidayAsSpacetime.date()
      );
    });
  }
});

// @ts-ignore
const canWeShipOnThisDate = (date) => {
  return !date.isFosdickHoliday() && date.dayName() !== 'saturday' && date.dayName() !== 'sunday';
};

export const firstSubscriptionChargeAndShipDate = () => {
  let n = 0;
  let potentialNextSubscriptionDate = spacetime.now().add(21, 'day');

  while (!canWeShipOnThisDate(potentialNextSubscriptionDate) && n < 5) {
    potentialNextSubscriptionDate = potentialNextSubscriptionDate.subtract(1, 'day');
    n++;
  }

  return potentialNextSubscriptionDate;
};
