/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/main.css');

const checkout = require('./src/integrations/shopify/checkout');
const { COMMIT_REF } = require('./.env.js');
const { Amplitude } = require('./src/utils/tracking');
const { sanitizeHttpBody } = require('./src/utils/httpSanitizer');
const { TrackingStrings } = require('./src/constants/analytics');

exports.onInitialClientRender = async () => {
  // Hydrate the checkout on client entry
  await checkout.hydrate();
  checkout.populateInventory();
  Amplitude.track(TrackingStrings.SITE_VISIT, { category: 'onInitialClientRender' });
};

exports.onClientEntry = () => {
  let dataSharingOptOut = false;
  if (typeof window !== 'undefined') {
    const cookies = Object.fromEntries(document.cookie.split('; ').map((x) => x.split('=')));
    // @ts-ignore globalPrivacyControl not available yet in browser API types
    dataSharingOptOut = cookies['cp_data_opt_out'] || navigator.globalPrivacyControl;
  }
};

exports.onRouteUpdate = ({ location }) => {
  anchorScroll(location);
  return true;
};
exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.hash) {
    anchorScroll(location);
  } else if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 600);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 600);
  }
  return false;
};

function anchorScroll(location) {
  if (location && location.hash) {
    setTimeout(() => {
      // document.querySelector(`${location.hash}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (!document.querySelector(`${location.hash}`)) {
        console.warn(`Could not find hash ${location.hash} to scroll to.`);
      } else {
        const item = document.querySelector(`${location.hash}`).offsetTop || 0;
        const mainNavHeight = document.querySelector(`header`).offsetHeight || 0;

        window.scrollTo({
          top: item - mainNavHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
    }, 0);
  }
}
