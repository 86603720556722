import { Dispatch, SetStateAction } from 'react';
import stringify from 'safe-json-stringify';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';
import { RechargeLineItem } from 'src/types/rechargeTypes';
import * as handleItems from './handleShopifyLineItems';
import * as checkout from './checkout';
const GATSBY_API_URL = process.env.GATSBY_API_URL;

// 3681928937546
// Get shopify order object

interface ShopifyOrder {
  name: string; // "#97637
}

type FetchShopifyOrderByOrderId = ({ id }: { id: string | number }) => Promise<ShopifyOrder>;

export const fetchShopifyOrderByOrderId: FetchShopifyOrderByOrderId = async ({ id }) => {
  return await fetch(`${GATSBY_API_URL}/shopify/get-order-by-order-id`, {
    method: 'POST',
    body: stringify({ orderId: id })
  }).then(async (res) => {
    res = await res.json();
    // @ts-ignore
    return res?.order as unknown as ShopifyOrder;
  });
};

/*
  Update Customer Tags
 */

type UpdateCustomerTags = ({
  email,
  tags
}: {
  email: string;
  tags: string[];
}) => Promise<string[] | Response>;

export const updateCustomerTags: UpdateCustomerTags = async ({ email, tags }) => {
  const body = {
    email,
    tags
  };

  return await fetch(`${GATSBY_API_URL}/shopify/update-tags`, {
    method: 'PUT',
    body: JSON.stringify(body)
  }).then(async (res) => {
    res = await res.json();
    return res;
  });
};

export interface Quantities {
  [id: string]: {
    lineItem: ShopifyLineItem | RechargeLineItem;
    price: { amount: string };
    quantity: number;
  };
}

export const updateShopifyCart = async (pendingQuantityChanges: Quantities) => {
  const changeEntries = Object.entries(pendingQuantityChanges);

  // update takes individual line items
  const itemsToUpdate = changeEntries
    .filter(([_, lineItemAndQuantity]) => lineItemAndQuantity.quantity > 0)
    .map(([_, item]) => {
      if ('id' in item.lineItem) {
        return {
          id: item.lineItem.id,
          quantity: item.quantity
        };
      } else {
        throw new Error('Cannot find product ID in attempt to remove product.');
      }
    });

  if (itemsToUpdate.length) {
    await checkout.update(itemsToUpdate);
  }

  // remove takes an array of IDs
  const itemsToRemove = changeEntries
    .filter(([_, quantitiesObject]) => quantitiesObject.quantity <= 0)
    .map(([_, item]) => {
      if ('id' in item.lineItem) {
        return item.lineItem.id;
      } else {
        throw new Error('Cannot find product ID in attempt to remove product.');
      }
    });

  if (itemsToRemove.length) {
    await checkout.remove(itemsToRemove);
  }
};

export const clearSamplerBoxAndSubscription = async (shopifyCart: ShopifyCart) => {
  const samplerBoxItems = shopifyCart.lineItems
    .filter(handleItems.samplerBoxItems)
    .map((item) => item.id);
  const subscriptionItems = shopifyCart.lineItems
    .filter(handleItems.subscriptionItems)
    .map((item) => item.id);

  await checkout.remove([...samplerBoxItems, ...subscriptionItems]);
};

// either v legitimate or v cursed use of closure

let counter = 0; // only run update on most recent invocation
let pendingChanges = {} as Quantities;
let timeout: ReturnType<typeof setTimeout> | null = null;

export function debounceCartUpdate(
  setUpdating: Dispatch<SetStateAction<boolean>>
): (
  quantityToUpdate: Quantities,
  quantities: Quantities,
  nearImmediate: boolean
) => ReturnType<typeof setTimeout> {
  return (item: Quantities, _: Quantities, nearImmediate: boolean) => {
    counter++;
    const count = counter;

    if (item) {
      pendingChanges = { ...pendingChanges, ...item };
    } else {
      console.error('No item received by debouncedCartUpdate');
    }

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(
      async () => {
        if (count === counter) {
          timeout = null;
          setUpdating(true);
          await updateShopifyCart(pendingChanges);
          setUpdating(false);
          pendingChanges = {};
        }
      },
      nearImmediate ? 0 : 500
    );

    return timeout;
  };
}
