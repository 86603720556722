import { ProductEnum } from 'src/types/types';
import 'array-flat-polyfill';

export const CHICKEN_PATE_SKU = 'CP-FWP-00004';
export const CHICKEN_SHREDS_SKU = 'CP-FWS-00004';
export const TURKEY_AND_CHICKEN_PATE_SKU = 'CP-FWP-00006';
export const TURKEY_AND_CHICKEN_SHREDS_SKU = 'CP-FWS-00006';
export const TURKEY_AND_CHICKEN_DRY_SKU = 'CP-FDD-00001';
export const TUNA_PATE_SKU = 'CP-FWP-00002';
export const TUNA_SHREDS_SKU = 'CP-FWS-00002';
export const SALMON_AND_TUNA_PATE_SKU = 'CP-FWP-00001';
export const SALMON_AND_TUNA_SHREDS_SKU = 'CP-FWS-00001';
export const SALMON_AND_TUNA_DRY_SKU = 'CP-FDD-00002';
export const MACKEREL_BREAM_PATE_SKU = 'CP-FWP-00003';
export const SALMON_MACKEREL_PATE_SKU = 'CP-FWP-00010';
export const SALMON_MACKEREL_SHREDS_SKU = 'CP-FWS-00010';
export const BEEF_PATE_SKU = 'CP-FWP-00008';
export const BEEF_SHREDS_SKU = 'CP-FWS-00008';
export const DUCK_AND_CHICKEN_PATE_SKU = 'CP-FWP-00005';
export const DUCK_AND_CHICKEN_SHREDS_SKU = 'CP-FWS-00005';
export const DUCK_AND_TURKEY_DRY_SKU = 'CP-FDD-00003';
export const DUCK_PATE_SKU = 'CP-FWP-00007';
export const DUCK_SHREDS_SKU = 'CP-FWS-00007';
export const SERVE_AND_STORE_SET_SKU = 'CP-KIT-00001';

const chicken = {
  shortName: ProductEnum.CHICKEN,
  fullName: 'Chicken',
  ingredients: ['chicken'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712873812042, 45909484011799],
      SHREDS: [30712873844810, 45909483979031]
    }
  },
  SKU: {
    PATE: CHICKEN_PATE_SKU,
    SHREDS: CHICKEN_SHREDS_SKU
  }
};

const chickenTurkey = {
  shortName: ProductEnum.CHICKEN_TURKEY,
  fullName: 'Chicken & Turkey',
  ingredients: ['chicken', 'turkey'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712873877578, 45909484077335],
      SHREDS: [30712873910346, 45909484044567],
      DRY: [30712874500170, 45909481062679]
    }
  },
  SKU: {
    PATE: TURKEY_AND_CHICKEN_PATE_SKU,
    SHREDS: TURKEY_AND_CHICKEN_SHREDS_SKU,
    DRY: TURKEY_AND_CHICKEN_DRY_SKU
  }
};

const tuna = {
  shortName: ProductEnum.TUNA,
  fullName: 'Tuna',
  ingredients: ['tuna'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712873943114, 45909484142871],
      SHREDS: [30712873975882, 45909484110103]
    }
  },
  SKU: {
    PATE: TUNA_PATE_SKU,
    SHREDS: TUNA_SHREDS_SKU
  }
};

const salmonTuna = {
  shortName: ProductEnum.SALMON_TUNA,
  fullName: 'Salmon & Tuna',
  ingredients: ['salmon', 'tuna'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874041418, 45909484208407],
      SHREDS: [30712874074186, 45909484175639],
      DRY: [30712874565706, 45909481128215]
    }
  },
  SKU: {
    PATE: SALMON_AND_TUNA_PATE_SKU,
    SHREDS: SALMON_AND_TUNA_SHREDS_SKU,
    DRY: SALMON_AND_TUNA_DRY_SKU
  }
};

const tunaSalmon = {
  shortName: ProductEnum.TUNA_SALMON,
  fullName: 'Tuna & Salmon',
  ingredients: ['salmon', 'tuna'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874041418, 45909484208407],
      SHREDS: [30712874074186, 45909484175639],
      DRY: [30712874565706, 45909481128215]
    }
  },
  SKU: {
    PATE: SALMON_AND_TUNA_PATE_SKU,
    SHREDS: SALMON_AND_TUNA_SHREDS_SKU,
    DRY: SALMON_AND_TUNA_DRY_SKU
  }
};

const mackerelBream = {
  shortName: ProductEnum.MACKEREL_BREAM,
  fullName: 'Mackerel & Bream',
  ingredients: ['mackerel', 'bream'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874106954, 45909484273943],
      SHREDS: [30712874139722, 45909484241175]
    }
  },
  SKU: {
    PATE: MACKEREL_BREAM_PATE_SKU,
    SHREDS: null
  }
};

const salmonMackerel = {
  shortName: ProductEnum.SALMON_MACKEREL,
  fullName: 'Salmon & Mackerel',
  ingredients: ['salmon', 'mackerel'],
  variantIDs: {
    ONE_OFF: {
      PATE: [40567721263178, 47567639871767],
      SHREDS: [40567721197642, 47567638659351]
    }
  },
  SKU: {
    PATE: SALMON_MACKEREL_PATE_SKU,
    SHREDS: SALMON_MACKEREL_SHREDS_SKU
  }
};

const beef = {
  shortName: ProductEnum.BEEF,
  fullName: 'Beef',
  ingredients: ['beef'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874172490, 45909484470551],
      SHREDS: [30712874205258, 45909484437783]
    }
  },
  SKU: {
    PATE: BEEF_PATE_SKU,
    SHREDS: BEEF_SHREDS_SKU
  }
};

const duckChicken = {
  shortName: ProductEnum.DUCK_CHICKEN,
  fullName: 'Duck & Chicken',
  ingredients: ['duck', 'chicken'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874238026, 45909484339479],
      SHREDS: [30712874270794, 45909484306711]
    }
  },
  SKU: {
    PATE: DUCK_AND_CHICKEN_PATE_SKU,
    SHREDS: DUCK_AND_CHICKEN_SHREDS_SKU
  }
};

const duck = {
  shortName: ProductEnum.DUCK,
  fullName: 'Duck',
  ingredients: ['duck'],
  variantIDs: {
    ONE_OFF: {
      PATE: [30712874303562, 45909484405015],
      SHREDS: [30712874336330, 45909484372247]
    }
  },
  SKU: {
    PATE: DUCK_PATE_SKU,
    SHREDS: DUCK_SHREDS_SKU
  }
};

const duckTurkey = {
  shortName: ProductEnum.DUCK_TURKEY,
  fullName: 'Duck & Turkey',
  ingredients: ['duck', 'turkey'],
  variantIDs: {
    ONE_OFF: {
      DRY: [30712874532938, 45909481095447]
    }
  },
  SKU: {
    DRY: DUCK_AND_TURKEY_DRY_SKU
  }
};

export const wetFlavors = [
  chicken,
  tuna,
  chickenTurkey,
  mackerelBream,
  duckChicken,
  duck,
  beef,
  tunaSalmon,
  salmonMackerel
];

export const dryFlavors = [chickenTurkey, salmonTuna, duckTurkey];

export const wetFlavorSKUs = wetFlavors
  .map((flavor) => [flavor.SKU.SHREDS, flavor.SKU.PATE].flat())
  .flat();

export const wetFlavorVariantIDs = wetFlavors
  .map((flavor) => [flavor.variantIDs.ONE_OFF.PATE, flavor.variantIDs.ONE_OFF.SHREDS].flat())
  .flat();

export const dryFlavorSKUs = dryFlavors.map((flavor) => [flavor.SKU.DRY].flat()).flat();

export const dryFlavorVariantIDs = dryFlavors
  .map((flavor) => [flavor.variantIDs.ONE_OFF.DRY].flat())
  .flat();
