export const GOODNESS_BLENDS_COST = 19.5;

export const TEXTURES = {
  PATE: 'Paté',
  SHREDS: 'Shreds'
};

export const WET = 'wet';
export const WET_DRY = 'wet-and-dry';
export const PATE = 'paté';
export const DRY = 'dry';
export const SHREDS = 'shreds';
export const VARIETY = 'variety';

export type TextureSelection = typeof SHREDS | typeof PATE | typeof VARIETY;
