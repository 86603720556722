export const TrackingStrings = {
  EVENT_CATEGORY_MEAL_PLAN_PAGE: 'meal_plan_page',
  EVENT_CATEGORY_DRY_AND_WET_QUIZ: 'dry_and_wet_food_quiz',
  EVENT_CATEGORY_WET_ONLY_QUIZ: 'wet_food_quiz',
  EVENT_CATEGORY_DRY_ONLY_QUIZ: 'dry_food_quiz',
  EVENT_CATEGORY_UNSET_STARTER_TYPE: 'unset_starter_type',
  EVENT_ACTION_QUIZ_STEP_VIEWED: 'quiz_step_viewed',
  ANSWERED: 'answered',
  QUIZ_STEP: 'quiz_step',
  ALLERGIC: 'allergic',
  VIEWED: 'viewed',
  MEAL_PLAN_PAGE: 'meal_plan_page',
  PLAN_SELECTED: 'plan_selected',
  REVIEW: 'review',
  FAQS: 'faqs',
  CHECKOUT: 'checkout',
  CONTINUE_TO_PAY: 'continue_to_payment',
  PLACE_ORDER: 'place_order',
  EDIT_FLAVOR: 'edit_flavor',
  MEAL_PLAN: 'meal_plan',
  EDIT_FLAVORS: 'edit_flavors',
  EDIT_STARTER_FLAVOR_SAVED: 'edited_starter_flavors_saved',
  EDIT_SUBS_FLAVOR_SAVED: 'edited_meal_plan_flavor_saved',
  EDIT_STARTER_FLAVOR_CLICKED: 'edit_starter_flavors_clicked',
  EDIT_SUBS_FLAVOR_CLICKED: 'edit_meal_plan_flavor_clicked',
  CLICKED: 'clicked',
  SHIPPING_ADDR_ADDED: 'shipping_address_added',
  SAVED: 'saved',
  STARTER_BOX: 'starter_box',
  ORDER_PLACED: 'order_placed',
  ORDER_OVERVIEW: 'order_overview',
  CART: 'cart',
  OPEN_CART: 'open_cart',
  ADD_TO_CART: 'add_to_cart',
  ADD_BUNDLE_TO_CART: 'add_bundle_to_cart',
  REMOVE_ITEM: 'remove_item',
  CHECKOUT_CLICKED: 'checkout_clicked',
  DECREASE_QUANTITY: 'decrease_quantity',
  INCREASE_QUANTITY: 'increase_quantity',
  ADD_UPSELL: 'add_upsell',
  PRODUCT_COLLECTION: 'product_collection',
  PRODUCT_TILE: 'product_tile',
  PRODUCT_FEATURED: 'product_featured',
  PRODUCT_CARD: 'product_card',
  PRODUCT_DETAILS: 'product_details',
  MANUAL_ADJUST_QUANTITY: 'manual_adjust_quantity',
  PRODUCT_SELECTED: 'product_selected',
  COLLECTION_SELECTED: 'collection_selected',
  SHOP_NAVIGATION_BAR: 'shop_navigation_bar',
  APPLE_PAY_CANCEL: 'apple_pay_cancel',
  APPLE_PAY_TOKENIZE_SUCCESS: 'apple_pay_tokenize_success',
  APPLE_PAY_PRESENT: 'apple_pay_present',
  PAYPAL_PRESENT: 'paypal_present',
  EMAIL_NOT_REQUIRED: 'email_not_required',
  ADD: 'add',
  CANCEL: 'cancel',
  PLUS: 'plus',
  MINUS: 'minus',
  REMOVE: 'remove',
  EDIT_MP: 'edit_mp',
  SAVE: 'save',
  ORDER_HISTORY: 'order_history',
  ACCOUNT_PORTAL_NAV: 'ac_nav',
  PAYMENT_DETAILS: 'payment_details',
  ACCOUNT_INFO: 'account_info',
  ORDER: 'order',
  UPDATE_CARD: 'update_card',
  CHANGE: 'change',
  ADJUST_FREQ: 'adjust_frequency',
  EDIT_ADDRESS: 'edit_address',
  STATUS_COMPLETE: 'complete',
  EVENT_TEXTURE_CHANGED: 'texture_changed',
  EVENT_FLAVOR_CHANGED: 'flavor_changed',
  EVENT_COLOR_CHANGED: 'color_changed',
  BREADCRUMB_CLICKED: 'breadcrumb_clicked',
  SITE_VISIT: 'site_visit',
  LARGE_MEAL_PLAN_MODAL_SHOWN: 'large_meal_plan_modal_shown'
};

export const TrackingEventCategories = {
  EVENT_CATEGORY_ACCOUNT_PORTAL: 'account_portal',
  EVENT_CATEGORY_PDP: 'pdp',
  EVENT_CAT_HOME_PAGE: 'home_page'
};

export const TrackingEventNames = {
  EVENT_NAME_LOGGED_IN: 'user_logged_in',
  EVENT_NAME_ACCOUNT_PORTAL_VIEWED: 'account_portal_viewed',
  EVENT_NAME_MP_GET_STARTED: 'mp_get_started',
  EVENT_NAME_MP_SET_SHIP_DATE: 'setup_shipment_date',
  EVENT_NAME_ADD_RECIPE_DD: 'add_recipe_dropdown',
  EVENT_NAME_ADD_RECIPE: 'add_recipe',
  EVENT_NAME_CANCEL_ADD_RECIPE: 'cancel_adding_recipe',
  EVENT_NAME_PLUS_ITEM: 'plus_item',
  EVENT_NAME_MINUS_ITEM: 'minus_item',
  EVENT_NAME_REMOVE_ITEM: 'remove_item',
  EVENT_NAME_MP_EDIT_SAVE: 'mp_save_changes',
  EVENT_NAME_MP_EDIT_CANCEL: 'mp_cancel_changes',
  EVENT_NAME_MP_EDIT_ITEMS: 'mp_edit_items',
  EVENT_NAME_ONE_TIME_DD: 'one_time_item_dropdown',
  EVENT_NAME_ONE_TIME_ADDED: 'one_time_item_added',
  EVENT_NAME_PLUS_ONETIME_ITEM: 'plus_onetime_item',
  EVENT_NAME_MINUS_ONETIME_ITEM: 'minus_onetime_item',
  EVENT_NAME_REMOVE_ONETIME_ITEM: 'remove_onetime_item',
  EVENT_NAME_EDIT_PAYMENT_DETAILS: 'edit_payment_details',
  EVENT_NAME_UPDATE_CARD_PAYMENT_DETAILS: 'update_card_payment_details',
  EVENT_NAME_ADD_CARD_PAYMENT_DETAILS: 'add_card_payment_details',
  EVENT_NAME_CANCEL_PAYMENT_DETAILS: 'cancel_payment_details',
  EVENT_NAME_SWITCH_TO_CARD_APPPLE_PAY: 'switch_to_card_from_applepay',
  EVENT_NAME_SWITCH_TO_CARD_PAYPAL: 'switch_to_cart_from_paypal',
  EVENT_NAME_CHG_SHIP_DATE: 'change_shipment_date',
  EVENT_NAME_SKIP_SHIPMENT: 'skip_shipment',
  EVENT_NAME_CNF_SKIP_SHIPMENT: 'confirm_skip_shipment',
  EVENT_NAME_CANCEL_SHIP_DATE: 'cancel_shipment_date',
  EVENT_NAME_SAVE_SHIP_DATE: 'save_shipment_date',
  EVENT_NAME_CHANGE_FREQ: 'change_frequency',
  EVENT_NAME_SAVE_CHANGE_FREQ: 'save_frequency_change',
  EVENT_NAME_CNFM_SAVE_CHANGE_FREQ: 'confirm_save_frequency_change',
  EVENT_NAME_EDIT_SHIP_ADDRESS: 'edit_shipping_address',
  EVENT_NAME_SAVE_SHIP_ADDRESS: 'save_shipping_address',
  EVENT_NAME_CNCL_EDIT_SHIP_ADDRESS: 'cancel_shipping_address',
  EVENT_NAME_RESET_PASSWORD: 'reset_password',
  EVENT_NAME_RESET_PASSWORD_REQ: 'reset_password_requested',
  EVENT_NAME_EDIT_SHIP_ADD_ACCT_INFO: 'edit_shipping_address_account_info',
  EVENT_NAME_SAVE_SHIP_ADD_ACCT_INFO: 'save_shipping_address_account_info',
  EVENT_NAME_CANCEL_SHIP_ADD_ACCT_INFO: 'cancel_shipping_address_account_info',
  EVENT_NAME_LOGOUT: 'logout',
  EVENT_NAME_CANCEL_MP_CNFRM: 'cancel_meal_plan_confirm',
  EVENT_NAME_CANCEL_MP_START: 'cancel_meal_plan_start',
  EVENT_NAME_EDIT_BILL_ADD_ACCT_INFO: 'edit_billing_address_account_info',
  EVENT_NAME_SAVE_BILL_ADD_ACCT_INFO: 'save_billing_address_account_info',
  EVENT_NAME_CANCEL_BILL_ADD_ACCT_INFO: 'cancel_billing_address_account_info',
  EVENT_NAME_CARD_EXPIRY_MODAL_CLOSE: 'card_expiry_modal_close',
  EVENT_NAME_CARD_EXPIRY_UPDATE_CARD: 'card_expiry_update_card',
  EVENT_NAME_CARD_EXPIRY_MODAL_VIEWED: 'card_expiry_modal_viewed',
  EVENT_HOME_PAGE_HERO_BTN: 'hero_button',
  EVENT_HOME_PAGE_HW_IT_WRKS_BTN: 'how_it_works_button',
  EVENT_HOME_PAGE_MP_PLAN_BENEFITS_BTN: 'meal_plan_benefits_button',
  EVENT_HOME_PAGE_FOOD_BENEFITS_BTN: 'food_benefits_button',
  EVENT_HOME_PAGE_SHOP_BTN: 'shop',
  EVENT_HOME_PAGE_ABOUT_CP_BTN: 'about_cat_person_button',
  EVENT_PAUSE_MP_CLICK: 'pause_meal_plan_clicked',
  EVENT_PAUSE_MP_SUCCESS: 'pause_meal_plan_success',
  PROMO_ADDED: 'promo_added'
};

export const TrackingEventActions = {
  EVENT_ACTION_LOGIN: 'login'
};

export const TrackingEventValues = {
  EVENT_VALUE_SUCCESS: 'success',
  EVENT_VALUE_FAILED: 'failed',
  EVENT_VALUE_MP_STATUS_ACTIVE: 'active',
  EVENT_VALUE_MP_STATUS_NEVER: 'never_had_mp',
  EVENT_VALUE_MP_STATUS_INACTIVE: 'inactive'
};

export interface KlaviyoEvent {
  email: string;
  event: string;
  properties: object;
}
