import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import queryString from 'query-string';
import { Picostate, usePicostate } from '@picostate/react';
import { Header } from 'src/components/header';
import { MobileMenu } from 'src/components/mobileMenu';
import { DesktopMenu } from 'src/components/desktopMenu';
import { CartWidget } from 'src/components/cart/CartWidget';
import store from 'src/state/store';
import { transcribeParamsToCustomAttributes } from 'src/integrations/shopify/checkout';
import { BannerBarProps } from '../modules/bannerBar';
import { StateInterface } from '../state/initialState';
import { navigate } from 'gatsby';

const closeNavs = () => {
  store.hydrate({
    navOpen: false
  })();
};

const storeReferrerInformation = async (
  _: any,
  {
    location,
    checkoutId
  }: {
    location: { [propName: string]: string };
    checkoutId: string;
  }
) => {
  const parsedQuery = location?.search
    ? (queryString.parse(location?.search) as { [key: string]: string })
    : {};
  console.info('Storing referrer information:', parsedQuery, `for checkoutId ${checkoutId}`);

  const structuredStateParams = Object.keys(parsedQuery)
    .map((key) => {
      if (key === 'offerId') {
        return {
          key,
          value: parsedQuery[key].toLowerCase()
        };
      } else
        return {
          key,
          value: parsedQuery[key]
        };
    })
    .filter((p) => !Array.isArray(p.value));

  if (Object.keys(parsedQuery).length)
    await transcribeParamsToCustomAttributes(structuredStateParams, checkoutId);
};

const LayoutInner = (props: {
  children: React.ReactNode;
  location: { [propName: string]: string };
  background?: boolean;
  projectNav?: boolean;
}) => {
  const [picostate, actions] = usePicostate({ storeReferrerInformation });
  const { navOpen } = picostate as StateInterface;

  useEffect(() => {
    if (picostate.checkoutId)
      actions.storeReferrerInformation({
        location: props.location,
        checkoutId: picostate.checkoutId
      });
  }, [picostate.checkoutId]);

  return (
    <div
      className={cx('a-color site', {
        'bcb cw': props.background,
        'bcw cb': !props.background
      })}
    >
      <div
        onClick={closeNavs}
        className={cx('site__overlay top left', {
          'is-active': props.projectNav || navOpen
        })}
      />
      {props.children}
    </div>
  );
};

const Layout = ({
  uri,
  children,
  location,
  pageContext
}: {
  uri: string;
  children: React.ReactNode;
  location: { [propName: string]: string };
  pageContext: {
    location: string;
    misc: { cartUpsells?: any };
    bannerBar: BannerBarProps;
    headerHeight: number;
  };
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  pageContext.headerHeight = headerHeight;

  const kustomerScript = () => {
    window.addEventListener('kustomerLoaded', function () {
      // @ts-ignore
      if (window.Kustomer) window.Kustomer.start();
    });

    // Dynamically adds the SDK to the page.
    const script = document.createElement('script');
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
    script.setAttribute(
      'data-kustomer-api-key',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwYjkwYTNkOTAzODIyMDA5NmQ5ZThkNCIsInVzZXIiOiI2MGI5MGEzODIxZTIxODAwMWE0ZDY5Y2EiLCJvcmciOiI1ZTE1ZmY5ZWEzNzA1YjAwMTM2Yjk3ZDMiLCJvcmdOYW1lIjoia2F0cyIsInVzZXJUeXBlIjoibWFjaGluZSIsInBvZCI6InByb2QxIiwicm9sZXMiOlsib3JnLmFkbWluIiwib3JnLnVzZXIiXSwiYXVkIjoidXJuOmNvbnN1bWVyIiwiaXNzIjoidXJuOmFwaSIsInN1YiI6IjYwYjkwYTM4MjFlMjE4MDAxYTRkNjljYSJ9.gX-wYjHC18bj1bd2pG6EhcmsCa_k1CeA4GMQiuMqqVg'
    );
    window.document.body.appendChild(script);
  };

  useEffect(() => {
    kustomerScript();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log('url from src/layouts', window.location.href);

      if (window.location.href.includes('support.catperson.com')) {
        navigate('https://help.weruva.com');
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log('url', window.location.href);

      if (window.location.href.includes('support.catperson.com')) {
        navigate('https://help.weruva.com');
        return;
      }
    }
  }, []);

  return (
    <Picostate store={store}>
      <React.Fragment>
        <LayoutInner location={location}>
          <React.Fragment>
            <Header pageContext={pageContext} setHeaderHeight={setHeaderHeight} />
            <MobileMenu pageContext={pageContext} />
            <DesktopMenu pageContext={pageContext} />
            <main id="maincontent">{children}</main>
            <CartWidget upsells={pageContext?.misc?.cartUpsells} />
          </React.Fragment>
        </LayoutInner>
      </React.Fragment>
    </Picostate>
  );
};

export default Layout;
