import React, { useState, useEffect } from 'react';
import cookie from 'js-cookie';

import { firstSubscriptionChargeAndShipDate } from '../../shared/firstSubscriptionShipDate';
import { isNotFreeSubscription } from 'src/components/checkout/utils/calculate-totals';

export const RenderSubscriptions = ({
  items
}: {
  items: any[];
  names: Array<{ name: string }>;
}) => {
  const [_, setCatNames] = useState([]);
  const totalPrice: () => number = () => {
    let price = 0;
    items.filter(isNotFreeSubscription).forEach((item) => {
      price += parseFloat(item.variant.price.amount) * item.quantity;
    });
    return price;
  };

  useEffect(() => {
    const catCookie = cookie.get('cat_names');
    if (catCookie) {
      const catConvert = JSON.parse(catCookie);
      setCatNames(catConvert);
    }
  }, [0]);

  return (
    <div className="py1 cart__sub rel mb1">
      <div className="pb05">
        <h5 className="m0 p0 s16 sans-b mb0">Meal Plan</h5>
        <span className="small">
          Ships &amp; Charges {firstSubscriptionChargeAndShipDate().unixFmt('MMM. dd')} &amp; every
          4 weeks.
        </span>
      </div>
      <div className="al s16 sans">
        <span className="sans strike mr05">${totalPrice()}</span>
        <span className="sans-b">${(totalPrice() * 0.9).toFixed(2)}</span>
      </div>
    </div>
  );
};
