export const OFFER_PRICE = 'offerPrice';
export const ORIGINAL_PRICE = 'originalPrice'
export const DISCOUNT_STARTER_BOX_TYPE = "discountStarterBoxType"
export const OFFER_ID = 'offerId';

export type DiscountDetails =
  | typeof OFFER_ID
  | typeof OFFER_PRICE
  | typeof ORIGINAL_PRICE
  | typeof DISCOUNT_STARTER_BOX_TYPE

export interface DiscountAnswers {
  [OFFER_ID]: string | undefined;
  [OFFER_PRICE]: number | undefined;
  [ORIGINAL_PRICE]: number | undefined;
  [DISCOUNT_STARTER_BOX_TYPE]: string | undefined;
}

export const defaultDiscountResults: DiscountAnswers = {
  [OFFER_ID]: '',
  [OFFER_PRICE]: undefined,
  [ORIGINAL_PRICE]: undefined,
  [DISCOUNT_STARTER_BOX_TYPE]: undefined
};
