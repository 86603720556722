import cookie from 'js-cookie';
import { getShopifyProduct, shopifyClient as shop } from '../shopify';
import store from '../../state/store';

/* Query Parameter Functions */

export interface Param {
  key: string;
  value: undefined | string;
}

// Marketing + Attribution Params
export const externalQueryParams: Param[] = [{ key: 'irclickid', value: undefined }];

export const additionalOrderParams: Param[] = [
  { key: 'varietyPack', value: undefined },
  { key: 'populateQuizResults', value: undefined }
];

export const transcribeParamsToCustomAttributes = async (params: Param[], checkoutId?: string) => {
  const keysInParamsArgument = params.map((param) => param.key);

  const defaultParams = externalQueryParams
    .concat(additionalOrderParams)
    .filter((keyValuePair) => !keysInParamsArgument.includes(keyValuePair.key));

  const paramsToLoopOver = defaultParams.concat(params);

  paramsToLoopOver.forEach((param) => {
    if (!param.value && !keysInParamsArgument.includes(param.key)) {
      param.value =
        store.state.params.find((stateParam: Param) => stateParam.key === 'key')?.value ||
        cookie.get(param.key);
    }

    if (param.key !== 'populateQuizResults' && param.value) {
      cookie.set(param.key, param.value);
    }
  });

  await store.hydrate({ params: paramsToLoopOver })();
  const attributesToSet = paramsToLoopOver.filter((param) => param.value);

  if (!checkoutId && !store.state.checkoutId) {
    console.warn(
      'No checkoutId to transcribe params for, but params set in state',
      store.state.params
    );
    return;
  }

  await shop.checkout

    .updateAttributes(checkoutId || store.state.checkoutId, {
      // @ts-ignore
      customAttributes: attributesToSet
    })
    .then((cart) => {
      if (checkoutId && cart) {
        store.hydrate({
          checkoutId,
          shopifyCart: cart
        })();
      }
    });
};

/* Shopify Checkout Functions */

export function create() {
  return shop.checkout
    .create()
    .then((checkout) => {
      const checkoutId = checkout.id;

      cookie.set('customer_cart', checkoutId as string, {
        expires: 25
      });
      const customerToken = cookie.get('customer_token');
      const customerEmail = cookie.get('customer_email');
      const firstName = cookie.get('customer_firstName');

      store.hydrate({
        checkoutId,
        shopifyCart: checkout,
        customerToken,
        email: customerEmail,
        firstName
      })();

      return checkout;
    })
    .catch((e) => {});
}

export function populateInventory() {
  getShopifyProduct(undefined).then((products) => {
    const variantInventory = [] as Array<{
      id: number;
      available: boolean;
    }>;
    products?.forEach((product) => {
      product.variants.forEach((variant) => {
        const shopifyVariant = {
          id: variant.id,
          available: variant.available
        };
        variantInventory.push(shopifyVariant);
      });
    });

    store.hydrate({ inventory: variantInventory })();
  });
}

export const doesCheckoutExist = (value: unknown): boolean => value !== null;

export const hasValidCheckoutId = async (checkoutId = ''): Promise<boolean> => {
  try {
    const checkout = await shop.checkout.fetch(checkoutId);
    return doesCheckoutExist(checkout);
  } catch (e) {
    console.error(e);
    return false;
  }
};

export async function hydrate() {
  const checkoutId = cookie.get('customer_cart') ?? '';
  const customerToken = cookie.get('customer_token');
  const customerEmail = cookie.get('customer_email');
  const firstName = cookie.get('customer_firstName');
  const catNamesCookie = cookie.get('cat_names');

  store.hydrate({
    checkoutId
  })();

  if (catNamesCookie) {
    try {
      const catNameArray = JSON.parse(catNamesCookie);
      store.hydrate({
        catNames: catNameArray
      })();
    } catch (e) {
      console.info(`Unable to restore catNames state from cookie ${catNamesCookie}`);
    }
  }

  const hasCheckoutId = await hasValidCheckoutId(checkoutId);
  if (hasCheckoutId && customerEmail) {
    return shop.checkout
      .fetch(checkoutId)
      .then((checkout) => {
        if (typeof window !== 'undefined') {
          // @ts-ignore
          window.checkout = checkout;
        }

        let quantity = 0;

        if (!checkout.completedAt) {
          shop.checkout.updateEmail(checkoutId, customerEmail);

          checkout.lineItems.forEach((item, i) => {
            quantity += item.quantity;
            // @ts-ignore
            checkout.lineItems[i].smallImage = shop.image.helpers?.imageForSize(
              // @ts-ignore
              item.variant.image,
              {
                maxWidth: 300,
                maxHeight: 300
              }
            );
          });
        }

        return checkout.completedAt
          ? create()
          : store.hydrate({
              shopifyCart: checkout,
              quantity,
              customerToken,
              email: customerEmail,
              firstName
            })();
      })
      .catch((e) => {});
  } else if (hasCheckoutId) {
    return shop.checkout
      .fetch(checkoutId)
      .then((checkout) => {
        if (typeof window !== 'undefined') {
          // @ts-ignore
          window.checkout = checkout;
        }

        let quantity = 0;

        if (!checkout.completedAt) {
          checkout.lineItems.forEach((item, i) => {
            quantity += item.quantity;
            // @ts-ignore
            checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, {
              maxWidth: 300,
              maxHeight: 300
            });
          });
        }

        return checkout.completedAt
          ? create()
          : store.hydrate({
              shopifyCart: checkout,
              quantity,
              customerToken,
              email: customerEmail,
              firstName
            })();
      })
      .catch((e) => {});
  } else {
    return create().catch((e) => {});
  }
}

export async function add(items, replaceExisting = false) {
  items = [].concat(items);
  const checkoutMethod = replaceExisting
    ? shop.checkout.replaceLineItems
    : shop.checkout.addLineItems;

  return checkoutMethod
    .call(shop.checkout, store.state.checkoutId, items)
    .then((checkout) => {
      let quantity = 0;
      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity;

        /* @ts-ignore */
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, {
          maxWidth: 300,
          maxHeight: 300
        });
      });
      store.hydrate({ shopifyCart: checkout, quantity })();
      return checkout;
    })
    .catch((e) => {
      throw e;
    });
}

export const addItemToShopifyCart = add;

export function remove(ids: Array<number | string>) {
  ids = ([] as Array<number | string>).concat(ids);

  return shop.checkout
    .removeLineItems(store.state.checkoutId, ids as string[])
    .then((checkout) => {
      let quantity = 0;
      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity;

        // @ts-ignore
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, {
          maxWidth: 100,
          maxHeight: 100
        });
      });
      store.hydrate({ shopifyCart: checkout, quantity })();
    })
    .catch((e) => {});
}

export function update(items) {
  items = [].concat(items);

  return shop.checkout

    .updateLineItems(store.state.checkoutId, items)

    .then((checkout) => {
      let quantity = 0;

      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity;
        // @ts-ignore
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, {
          maxWidth: 100,
          maxHeight: 100
        });
      });
      store.hydrate({ shopifyCart: checkout, quantity })();
    })
    .catch((e: Error) => {});
}

export function updateEmail(email: string) {
  return shop.checkout

    .updateEmail(store.state.shopifyCart.id, email)

    .then((checkout) => {
      store.hydrate({ shopifyCart: checkout })();
      store.hydrate({ email })();
      cookie.set('customer_email', email, { expires: 25 });

      // @ts-ignore
      return checkout.customerToken;
    })
    .catch((e: Error) => {});
}

export async function addAttributesToShopifyCart(
  attributes: Array<{ key: string; value: string }>
) {
  const newAttributes = [
    ...(store.state?.shopifyCart.customAttributes?.map((note: { key: string; value: string }) => ({
      key: note.key,
      value: note.value
    })) || []),
    ...attributes
  ];

  return shop.checkout

    .updateAttributes(store.state.checkoutId, {
      customAttributes: newAttributes
    })
    .then((checkout) => {
      store.hydrate({ shopifyCart: checkout })();
    })
    .catch((e: Error) => {
      console.error(e);
    });
}

export const addDiscount = async (discountCode: string) => {
  await shop.checkout.addDiscount(store.state.checkoutId, discountCode);
  const checkout = await shop.checkout.fetch(store.state.checkoutId);
  store.hydrate({ shopifyCart: checkout })();
  return checkout;
};

// Swaps items in cart with given variants and applies discount code
// Returns a checkout.catperson.com URL to render Shopify checkout
export async function createBundle(variants, discountCode) {
  if (variants.length === 0) {
    throw new Error('Variants not found');
  }

  const variantCounts = {};
  variants.forEach((id) => {
    variantCounts[id] = (variantCounts[id] || 0) + 1;
  });

  const items = Object.entries(variantCounts).map(([id, count]) => ({
    variantId: `gid://shopify/ProductVariant/${id}`,
    quantity: count
  }));

  const addItemsResponse = await add(items, true);

  if (addItemsResponse.lineItems.length !== items.length) {
    throw new Error('Error adding one or more items');
  }

  if (discountCode && discountCode.length > 0) {
    const addDiscountResponse = await addDiscount(discountCode);

    if (addDiscountResponse.discountApplications.length === 0) {
      throw new Error('Error applying discount code');
    }
  }

  return addItemsResponse.webUrl;
}
