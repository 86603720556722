import stringify from 'safe-json-stringify';

export class RechargeException extends Error {
  public name: string;
  public message: string;
  public status?: number;
  public errorBody?: { [propName: string]: any } | undefined;
  public toString: () => string;

  constructor(
    message: string,
    status?: number | undefined,
    errorBody?: { [propName: string]: any } | undefined
  ) {
    super();

    this.name = 'RechargeException';
    this.message = message;
    this.status = status;
    this.errorBody = errorBody;
    this.toString = () => `${this.name}: "${this.message}"`;
  }
}

export const handleRechargeAPIError = async (
  res: Response,
  _: string,
  __: { [propName: string]: any }
): Promise<{ [propName: string]: any }> => {
  let data;

  if (!res) {
    const err = new RechargeException('No response.');
    throw err;
  } else if (res.status === 400) {
    let err;
    data = await res.json();
    if (data?.errors?.all === 'no response received from UPS after 5 seconds') {
      err = new RechargeException(
        'There was an error processing. Please try again.',
        res.status,
        data
      );
    } else if (data?.errors?.all === 'No Address Candidate Found') {
      err = new RechargeException(
        'Invalid address. Please check your address and try again.',
        res.status,
        data
      );
    } else {
      err = new RechargeException(
        'Invalid information. Please check your information and try again.',
        res.status,
        data
      );
    }
    throw err;
  } else if (res.status === 409 || res.status === 429) {
    const err = new RechargeException(
      'Our server is overwhelmed with customer requests, please try again.',
      res.status,
      await res.json()
    );
    throw err;
  } else if (res.status === 422) {
    // Some status codes may indicate user error, and these special cases need to be capture to render
    // to the user, but not sent to LR.

    data = await res.json();

    if (Array.isArray(data?.error?.errors) && data.error.errors?.length) {
      const error = Object.entries(data?.error?.errors)[0];

      // capture some errors - e.g. shipping errors - that can come back as objects
      if (typeof error[1] !== 'string') error[1] = stringify(error[1] as any)?.replace(/"/g, ``);
    }

    if (data?.error?.errors?.payment) {
      throw new RechargeException(
        `Card declined. Please check your information and try again.`,
        422,
        { Reason: data?.error?.errors?.payment[0] || '' }
      );
    } else if (data?.error?.errors?.shipping_address?.zip) {
      throw new RechargeException(
        'Invalid zip code. Please double-check your zip code and try again.',
        res.status,
        data
      );
    } else if (data?.error?.errors?.shipping_address?.province) {
      throw new RechargeException(
        'Invalid state and zip code. Please double-check and try again.',
        res.status,
        data
      );
    } else if (data?.error?.errors?.shipping_address?.country) {
      throw new RechargeException('Cannot ship to this country', res.status, data);
    } else if (
      Array.isArray(data?.error?.errors?.checkout) &&
      data.error.errors.checkout[0] === 'Checkout has already been processed.'
    ) {
      throw new RechargeException('Cart has already been processed.', res.status, data);
    } else {
      throw new RechargeException(
        `There was an internal error, please check your information try again. If the error persists, please get in touch with us.`,
        res.status,
        data
      );
    }
  } else if (res.status === 500 || res.status === 502 || res.status === 503 || res.status === 504) {
    const err = new RechargeException(
      'Something went wrong with our e-commerce provider. Please get in touch, or wait a few minutes and try again.',
      res.status,
      await res.json()
    );
    throw err;
  } else if (!res.ok) {
    const err = new RechargeException(
      `A server error occurred with status ${res.status}`,
      res.status,
      await res.json()
    );
    throw err;
  } else {
    data = await res.json();
    return data;
  }
};
