import React, { Dispatch, SetStateAction } from 'react';
import {
  AppliedDiscount,
  RechargeAddress,
  RechargeProcessedCheckout
} from 'src/types/rechargeTypes';
import { ShopifyCart } from 'src/types/shopifyTypes';
import { isStringAndStringEqual } from '../Quiz/panels/utils/quizHelper';
import { StarterType } from 'src/constants/Bundles/options/bundleOptions';

export enum PaymentMethod {
  CARD = 'card',
  PAYPAL = 'paypal',
  DEFAULT_PAYMENT_METHOD = 'not_set',
  APPLEPAY = 'applepay'
}

export const isPaypal = isStringAndStringEqual(PaymentMethod.PAYPAL);
export const isApplePay = isStringAndStringEqual(PaymentMethod.APPLEPAY);
export const isCard = isStringAndStringEqual(PaymentMethod.CARD);

export interface CheckoutInterface {
  address: RechargeAddress;
  checkoutPending: boolean;
  checkoutPendingMessage: string;
  confirmedDiscount: undefined | AppliedDiscount;
  confirmedShopifyCart: undefined | ShopifyCart;
  currentShopifyCart: ShopifyCart;
  discountError: undefined | string;
  email: string;
  isEditingContactInformation: boolean;
  payPalLoaded: boolean;
  applePayLoaded: boolean;
  paymentMethodSelected: PaymentMethod;
  processedCheckoutResponse: null | RechargeProcessedCheckout;
  purchasedSamplerBox: boolean;
  queryParams: { [propName: string]: string };
  processedRechargeCheckoutShopifyOrderId: string | undefined;
  setAddress: Dispatch<SetStateAction<RechargeAddress>>;
  setAddressError: Dispatch<SetStateAction<null | Error>>;
  setBraintreeSetupError: Dispatch<SetStateAction<null | Error>>;
  setByPaypal?: true;
  setCheckoutError: Dispatch<SetStateAction<null | Error>>;
  setCheckoutPending: Dispatch<SetStateAction<boolean>>;
  setCheckoutPendingMessage: Dispatch<SetStateAction<string>>;
  setConfirmedDiscount: Dispatch<SetStateAction<undefined | AppliedDiscount>>;
  setDiscountError: Dispatch<SetStateAction<undefined | string>>;
  setEditingContactInformation: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPayPalLoaded: Dispatch<SetStateAction<boolean>>;
  setApplePayLoaded: Dispatch<SetStateAction<boolean>>;
  setPaymentMethodSelected: Dispatch<SetStateAction<PaymentMethod>>;
  setProcessedCheckoutResponse: Dispatch<SetStateAction<null | RechargeProcessedCheckout>>;
  setPurchasedSamplerBox: Dispatch<SetStateAction<boolean>>;
  setProcessedRechargeCheckoutShopifyOrderId: Dispatch<SetStateAction<string | undefined>>;
  setStep: Dispatch<SetStateAction<'address' | 'payment' | 'confirmation'>>;
  step: 'address' | 'payment' | 'confirmation';
  starterBoxType: StarterType;
  applePaySuccess: boolean;
  setApplePaySuccess: Dispatch<SetStateAction<boolean>>;
}

// @ts-ignore
export const CheckoutContext = React.createContext({} as CheckoutInterface);
