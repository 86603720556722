// @ts-ignore
import createStore from 'picostate';
import initialState from 'src/state/initialState';

export default (() => {
  const store = createStore(initialState);

  // @ts-ignore
  if (typeof window !== 'undefined') window.__store = store;

  return store;
})();
