const REDACTED = '********';

export const sanitizeAddress = (unsanitized) => {
  if (typeof unsanitized !== 'object' || unsanitized === null) return;

  if ('billing_address1' in unsanitized) {
    unsanitized.billing_address1 = REDACTED;
  }

  if ('billing_address2' in unsanitized) {
    unsanitized.billing_address2 = REDACTED;
  }

  if ('address1' in unsanitized) {
    unsanitized.address1 = REDACTED;
  }

  if ('address2' in unsanitized) {
    unsanitized.address2 = REDACTED;
  }

  if ('phone' in unsanitized) {
    unsanitized.phone = REDACTED;
  }

  if ('billing_phone' in unsanitized) {
    unsanitized.billing_phone = REDACTED;
  }

  if ('billing_address' in unsanitized) {
    sanitizeAddress(unsanitized.billing_address);
  }

  if ('shipping_address' in unsanitized) {
    sanitizeAddress(unsanitized.shipping_address);
  }

  if ('address' in unsanitized) {
    sanitizeAddress(unsanitized.address);
  }

  if ('addresses' in unsanitized && Array.isArray(unsanitized.addresses)) {
    unsanitized.addresses.forEach((address) => sanitizeAddress(address));
  }
};

export const sanitizeHttpBody = (input) => {
  if (typeof input !== 'string' || input.length === 0) return null;

  try {
    let body = JSON.parse(input);
  
    sanitizeAddress(body);

    if ('password' in body) {
      body.password = REDACTED;
    }

    if ('token' in body) {
      body.token = REDACTED;
    }

    if ('customer' in body) {
      sanitizeAddress(body.customer);

      if ('defaultAddress' in body.customer) {
        sanitizeAddress(body.customer.defaultAddress);
      }
    }

    if ('paymentMethod' in body) {
      sanitizeAddress(body.paymentMethod);
    }

    if ('paymentMethods' in body && Array.isArray(body.paymentMethods)) {
      body.paymentMethods.forEach((method) => sanitizeAddress(method));
    }

    if ('checkout' in body) {
      sanitizeAddress(body.checkout);
    }

    return body;
  } catch (error) {
    return null;
  }
};
