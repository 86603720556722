import { DRY, WET, WET_DRY } from 'src/constants/texture';

 export const starterTypeToDiscountKey = (applicableSanityDiscountCode, starterType) => {
   if (starterType === WET) {
     return applicableSanityDiscountCode?.wet_only_code;
   } else if (starterType === DRY) {
     return applicableSanityDiscountCode?.dry_only_code;
   } else if (starterType === WET_DRY) {
     return applicableSanityDiscountCode?.wet_dry_code;
   }
 };