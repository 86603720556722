import { useState, useEffect, useCallback } from 'react';
import { DiscountAnswers, DiscountDetails, defaultDiscountResults } from 'src/constants/Quiz/discounts';

export interface DiscountSession {
  isMounted: boolean;
  getDiscountResults: () => DiscountAnswers;
  clearDiscountSession: () => void;
  resetDiscountSession: () => void;
  setAllDiscountResults: (obj: DiscountAnswers) => void;
}

export const useDiscountSession = (): DiscountSession => {
  const [isMounted, setIsMounted] = useState(false);
  const defaultResults = JSON.stringify(defaultDiscountResults);

  // On page mount, we are no longer server side, so window is accessible
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Set our initial values in session storage once the page mounts
  useEffect(() => {
    if (isMounted && getDiscountResults() == null) {
      window.sessionStorage.setItem('discount', defaultResults);
    }
  }, [isMounted]);

  const setAllDiscountResults = useCallback(
    (discountObject: DiscountAnswers) => {
      if (isMounted) {
        let results = JSON.parse(window.sessionStorage.getItem('discount') as string);
        if (results != null) {
          results = { ...discountObject };
          return window.sessionStorage.setItem('discount', JSON.stringify(results));
        } else {
          let initialDiscountStore = { ...discountObject };
          return window.sessionStorage.setItem('discount', JSON.stringify(initialDiscountStore));
        }
      }
    },
    [isMounted]
  );

  // Fetch and parse the quiz results from session storage
  const getDiscountResults = (): DiscountAnswers => {
    if (isMounted) {
      const results = JSON.parse(window.sessionStorage.getItem('discount') as string);
      if (results != null) return results;
      return defaultDiscountResults;
    }

    return defaultDiscountResults;
  };

  // Remove all Discount answers from session storage
  const clearDiscountSession = () => {
    if (isMounted) window.sessionStorage.removeItem('discount');
  };

  // Reset the values to default
  const resetDiscountSession = () => {
    if (isMounted) {
      window.sessionStorage.removeItem('discount');
      window.sessionStorage.setItem('discount', defaultResults);
    }
  };

  return {
    isMounted,
    getDiscountResults,
    clearDiscountSession,
    resetDiscountSession,
    setAllDiscountResults
  };
};
