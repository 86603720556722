import { StarterType } from 'src/constants/Bundles/options/bundleOptions';
import { DRY, WET, WET_DRY } from 'src/constants/texture';
import { ShopifyLineItem } from 'src/types/shopifyTypes';
import { PurchaseType } from 'src/types/types';

export const getSubscriptionItems = (lineItems: ShopifyLineItem[]) =>
  lineItems.filter(
    (item) =>
      item.customAttributes.length && item.customAttributes[0]?.value === PurchaseType.SUBSCRIPTION
  );

export const getOneOffItems = (lineItems: ShopifyLineItem[]) =>
  lineItems.filter(
    (item) =>
      !item.customAttributes.length ||
      (item.customAttributes.length &&
        item.customAttributes[0]?.value !== PurchaseType.SUBSCRIPTION)
  );

export const determineTypeOfStarterBox = (lineItems: ShopifyLineItem[]): StarterType => {
  const filteredSamplerItems = lineItems.filter(samplerBoxItems);
  if (filteredSamplerItems?.length > 0) {
    const numberOfWetItems = filteredSamplerItems
      .filter(shopifyWetItems)
      .reduce((acc, lineItem) => acc + lineItem.quantity, 0);
    const numberOfDryItems = filteredSamplerItems
      .filter(shopifyDryItems)
      .reduce((acc, lineItem) => acc + lineItem.quantity, 0);

    if (numberOfWetItems >= 4) {
      return WET;
    } else if (numberOfDryItems >= 2) {
      return DRY;
    } else {
      return WET_DRY;
    }
  } else {
    return undefined;
  }
};

export const samplerBoxItems = (item: ShopifyLineItem) =>
  item.customAttributes.length &&
  item.customAttributes[0] &&
  item.customAttributes[0]?.value === PurchaseType.SAMPLER_BOX;

export const subscriptionItems = (item: ShopifyLineItem) =>
  item.customAttributes.length &&
  item.customAttributes[0] &&
  item.customAttributes[0]?.value === PurchaseType.SUBSCRIPTION;

export const oneOffItems = (item: ShopifyLineItem) =>
  !item.customAttributes.length ||
  (item.customAttributes[0] && item.customAttributes[0].value) === PurchaseType.ONE_OFF;

export const shopifyWetItems = (item: ShopifyLineItem) => item.title === 'Wet Food';
export const shopifyDryItems = (item: ShopifyLineItem) => item.title === 'Dry Food';

export const nonFoodItem = (item: ShopifyLineItem) =>
  item.title !== 'Wet Food' && item.title !== 'Dry Food';
