import cx from 'classnames';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import React, { ReactNode, useRef, useState } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import useComponentSize from '@rehooks/component-size';
import {
  getBasicImageProps,
  getFluidGatsbyImage
} from 'src/utils/getGatsbyImageProps/getGatsbyImageProps';

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID ?? '',
  dataset: process.env.GATSBY_SANITY_DATASET ?? ''
};

const DEFAULT_DPR = 1; // device pixel ratio, overridden by media queries below if high res device
const DEFAULT_QUALITY = 100; // default quality to provide to the Sanity CDN
const MAX_SRC_WIDTH = 1440; // maximum view width size we will size images for

export const Image = ({
  imageId,
  className,
  width,
  alt,
  src,
  dataTickle,
  critical,
  quality,
  dpr,
  style,
  onClick
}: {
  imageId?: string;
  width?: number;
  alt?: string;
  dataTickle?: number;
  className?: string;
  src?: string;
  critical?: boolean; // opt out of lazy loading behavior
  quality?: number;
  dpr?: number;
  style?: { [propName: string]: string };
  onClick?: () => void;
}) => {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef(null);
  const baseComponentWidth = useComponentSize(imageRef)?.width || Infinity;
  const windowWidth = typeof window !== 'undefined' ? window?.screen?.width : Infinity;

  /*

  Following variables are used for the src set size definition:

  `(max-width: ${maxWidth}px) 100vw, ${srcWidth}px`

  e.g.: at width maxWidth, this image would be sized at srcWidth pixels.

   */

  const maxWidth = Math.min(MAX_SRC_WIDTH, windowWidth);
  const srcWidth = Math.min(MAX_SRC_WIDTH, Math.max(width || 0, baseComponentWidth), windowWidth);

  let fluidProps;

  /* Process JPG's, resize them */

  if (imageId && !/gif/.test(imageId) && !/svg/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(
      imageId,
      {
        maxWidth,
        srcWidth,
        quality: quality || DEFAULT_QUALITY,
        dpr: dpr || DEFAULT_DPR
      },
      sanityConfig
    );
  }

  /* Do not resize SVGs */

  if (imageId && /svg/.test(imageId)) {
    const image = getBasicImageProps(imageId, sanityConfig);
    if (image) {
      src = image?.url;
    }
  }

  return (
    <div
      className={cx('image__block ma', className)}
      data-tickl={dataTickle}
      ref={imageRef}
      onClick={onClick}
    >
      {fluidProps ? (
        <ResponsiveImage fluidProps={fluidProps} alt={alt} style={style} setLoaded={setLoaded} />
      ) : (
        <img
          alt={alt}
          src={src}
          className={cx('x y block', {
            'is-loaded': loaded
          })}
          onLoad={() => {
            setLoaded(true);
          }}
          style={style}
        />
      )}
    </div>
  );
};

// This component emulates what the now-deprecated GatsbyImage component did.
// We may want to explore better options to reduce the complexity (CD-606)
const ResponsiveImage = ({ fluidProps, alt, style, setLoaded }) => {
  return (
    <div className="gatsby-image-wrapper" style={{ position: 'relative', overflow: 'hidden' }}>
      <div
        aria-hidden="true"
        style={{ width: '100%', paddingBottom: `${(1 / fluidProps.aspectRatio) * 100}%` }}
      ></div>
      <picture>
        <source type="image/webp" srcSet={fluidProps.srcSetWebp} sizes={fluidProps.sizes} />
        <img
          src={fluidProps.src}
          srcSet={fluidProps.srcSet}
          sizes={fluidProps.sizes}
          loading="lazy"
          alt={alt}
          onLoad={() => {
            setLoaded(true);
          }}
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
            opacity: '1',
            transition: 'all 0.2s ease 0s',
            ...style
          }}
        />
      </picture>
    </div>
  );
};
