import {
  RechargeSubscriptionItem,
  Subscription,
  SubscriptionQuantities,
  RechargeCharge
} from 'src/types/rechargeTypes';
import { TEXTURES } from '../constants/texture';
import { isStringAndStringEqual } from 'src/components/Quiz/panels/utils/quizHelper';
import { PDPVariant } from 'src/components/products/productForm';

const DEFAULT_RATE = 1;
export const QUOTIENT = 'QUOTIENT';
export const DIFFERENCE = 'DIFFERENCE';
export const PRODUCT = 'PRODUCT';
const TOTAL_DAYS_IN_MONTH = 28;
// TODO: CD-188 Read from discount set in Recharge
export const MONTHLY_DISCOUNT = 0.1;

const isQuotient = isStringAndStringEqual(QUOTIENT);
const isDifference = isStringAndStringEqual(DIFFERENCE);
const isProduct = isStringAndStringEqual(PRODUCT);

export const valuesToMathExpression =
  (string = '') =>
  (number1 = 0) =>
  (number2 = 0) => {
    if (isQuotient(string)) {
      const quotient = number1 / number2;
      return isNaN(quotient) ? 0 : quotient;
    }
    if (isDifference(string)) {
      return number1 - number2;
    }
    if (isProduct(string)) {
      return number1 * number2;
    }
    return number1 + number2;
  };

export const numberAndNumberToQuotient = valuesToMathExpression(QUOTIENT);

export const numberAndNumberToDifference = valuesToMathExpression(DIFFERENCE);

export const numberAndNumberToProduct = valuesToMathExpression(PRODUCT);

export const numberToDailyRate = (number = 0) =>
  numberAndNumberToQuotient(number)(TOTAL_DAYS_IN_MONTH);

const monthlyRate = numberAndNumberToDifference(DEFAULT_RATE)(MONTHLY_DISCOUNT);

export const numberToDiscountRate = numberAndNumberToProduct(monthlyRate);

export const isNumberPositive = (number = 0) => number > 0;

export const valueToLength = (array: unknown[] | string = []) => array?.length ?? 0;

export const hasLength = (value: string | unknown[]) => isNumberPositive(valueToLength(value));

export const subscriptionToNextCharge = (subscription: RechargeSubscriptionItem): string =>
  subscription.next_charge_scheduled_at ?? '';

export const compareDateStrings = (string1 = '', string2 = ''): number => {
  const date1 = new Date(string1);
  const date2 = new Date(string2);
  return date1.getTime() - date2.getTime();
};

export const subscriptionsToNextChargeDate = (
  subscriptions: RechargeSubscriptionItem[] = []
): string => {
  const dateStrings = subscriptions.map(subscriptionToNextCharge).sort(compareDateStrings);
  return dateStrings[0];
};

const isVariantAvailable = (variant: PDPVariant): boolean => variant.available;

const isVariantTextureAvailable =
  (texture = '') =>
  (variant: PDPVariant): boolean =>
    isVariantAvailable(variant) && variant.title.includes(texture);

export const valuesToFirstAvailableVariant = (
  decodedVariants: PDPVariant[] = [],
  texture = ''
): PDPVariant => {
  const availableTextureAndFlavor = decodedVariants.find(isVariantTextureAvailable(texture));
  const availableVariant = decodedVariants.find(isVariantAvailable);
  const firstVariant = decodedVariants[0];
  return availableTextureAndFlavor ?? availableVariant ?? firstVariant;
};

export const titleToShredsOrPete = (title: string): string => {
  if (title.includes(TEXTURES.PATE)) {
    return TEXTURES.PATE;
  } else if (title.includes(TEXTURES.SHREDS)) {
    return TEXTURES.SHREDS;
  } else {
    return '';
  }
};

export const getWetFoodFlavor = (x: PDPVariant) => x.title.split(' /')[0];
export const getWetFoodTexture = (x: PDPVariant) => x.title.split('/ ')[1];

export const isCustomerSubscription = ({ quantity }: Subscription): boolean =>
  isNumberPositive(quantity);

export const subscriptionQuantitiesToNumber = (subscriptionQuantities: SubscriptionQuantities) => {
  const subscriptions: Subscription[] = Object.values(subscriptionQuantities);
  const customerSubscriptions = subscriptions.filter(isCustomerSubscription);
  return valueToLength(customerSubscriptions);
};

export const nextQueuedShippingCharge = (nextCharge: RechargeCharge): number => {
  return parseFloat(nextCharge?.shipping_lines?.[0]?.price ?? '0');
};
