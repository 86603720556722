const sanityClient = require('@sanity/client');

module.exports = sanityClient({
  apiVersion: '2020-08-31',
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_API_TOKEN,
  ignoreBrowserTokenWarning: true,
  useCdn: false
});
