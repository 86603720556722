import React, { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
// @ts-ignore
import { connect } from '@picostate/react';
// @ts-ignore
import Modal from 'react-modal';

import PageLink from 'src/components/link';
import store from 'src/state/store';

import { foodNav } from 'src/integrations/nav';

import { Close } from 'src/components/svgs/svgs';

const Navs = [foodNav];

interface Props {
  shopOpen: boolean;
  pageContext: any;
}

const closeNav = () => {
  setTimeout(() => {
    store.hydrate({
      shopOpen: false
    })();
  }, 200);
};

export const DesktopMenu = connect((state: { shopOpen?: boolean }) => ({
  shopOpen: state.shopOpen
}))(({ shopOpen }: Props) => {
  const [hover] = useState(false as boolean);
  const videoRef = React.createRef() as React.RefObject<HTMLVideoElement>;
  const hoverVideo = React.createRef() as React.RefObject<HTMLVideoElement>;

  useEffect(() => {
    const menuVideo = videoRef.current;
    if (shopOpen) {
      if (menuVideo) menuVideo.play();
    }
    return () => menuVideo?.pause();
  }, [shopOpen]);

  return (
    <Modal ariaHideApp={false} isOpen={shopOpen} portalClassName="ReactModalPortal__nav">
      <div
        className={cx('abs x y top nav__shop nav__desktop left al bc-video z1', {
          open: shopOpen
        })}
      >
        <div
          className={cx('nav__video abs x y top left z2', {
            show: hover
          })}
        >
          <video
            ref={hoverVideo}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            className="abs x y top left"
          />
        </div>
        <div className="nav__mobile-video rel x y top left abs z1">
          <video
            ref={videoRef}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            className="abs x y top left"
          >
            <source src="https://player.vimeo.com/external/390337411.sd.mp4?s=c0f6fb0354c2caa0f3a3ffbefee8b1ff2659292d&profile_id=165" />
          </video>
        </div>
        <button
          className="nav__close remove--style cw cursor abs z4 top right p1"
          onClick={() => closeNav()}
        >
          <Close />
        </button>

        <div className="rel z3 outer f nav__desktop-blocks cw jcs p1 outer">
          {Navs.map((nav, i) => (
            <div className={cx('desktop-block', nav?.className)} key={i}>
              {nav.items.map(({ route, text, className }, n) => (
                <div key={n}>
                  <PageLink
                    focusState={shopOpen && i === 0 && n === 0}
                    to={route}
                    tabIndex={shopOpen ? 3 : -1}
                    className={className}
                    onClick={() => closeNav()}
                  >
                    {text}
                  </PageLink>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
});
