export enum TextureEnum {
  PATE = 'PATE',
  SHREDS = 'SHREDS',
  DRY = 'DRY',
  GOODNESS = 'GOODNESS',
  ONE = 'ONE' // Serve and Store
}

export enum PackagingEnum {
  WET = 'WET',
  DRY = 'DRY',
  GOODNESS = 'GOODNESS',
  SAVORYCHEWS = 'SAVORYCHEWS',
  ONE = 'ONE'
}

export enum PurchaseType {
  ONE_OFF = 'ONE_OFF',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SAMPLER_BOX = 'SAMPLER_BOX'
}

export enum ProductEnum {
  CHICKEN = 'chicken',
  TUNA = 'tuna',
  CHICKEN_TURKEY = 'chicken-turkey',
  SALMON_TUNA = 'salmon-tuna',
  TUNA_SALMON = 'tuna-salmon',
  MACKEREL_BREAM = 'mackerel-bream',
  SALMON_MACKEREL = 'salmon-mackerel',
  DUCK_CHICKEN = 'duck-chicken',
  DUCK = 'duck',
  BEEF = 'beef',
  DUCK_TURKEY = 'duck-turkey',
  SERVE_AND_STORE_SET = 'serve-and-store-set',
  SKIN_AND_COAT = 'skin-and-coat',
  BONE_AND_JOINT = 'bone-and-joint',
  COGNITIVE_HEALTH = 'cognitive-health',
  DIGESTIVE_SUPPORT = 'digestive-support',
  IMMUNE_SUPPORT = 'immune-support'
}

export interface AddressInformation {
  email?: string;
  first_name: string;
  last_name: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  zip: string;
  phone: string;
}
