export const foodNav = {
  className: 'foodNav',
  header: 'Shop Food',
  items: [
    {
      route: 'https://www.weruva.com/collections/cat-person-wet-foods',
      text: 'Wet Food',
      className: 'h2 cooper wetFood'
    },
    {
      route: 'https://www.weruva.com/collections/cat-person-dry-foods',
      text: 'Dry Food',
      className: 'h2 cooper dryFood'
    },
    {
      route: 'https://www.weruva.com/collections/cat-person-treats-toppers',
      text: 'Treats',
      className: 'h2 cooper treats'
    },
    {
      route: 'https://www.weruva.com/products/catnip-wand-toy',
      text: 'Toys',
      className: 'h2 cooper toys'
    },
    {
      route: 'https://www.weruva.com/collections/cat-person',
      text: 'Shop All',
      className: 'h2 cooper shop-all'
    }
  ]
};

export const companyNav = {
  className: 'companyNav',
  header: 'Company',
  items: [
    {
      route: 'https://www.weruva.com/collections/cat-person',
      text: 'Food Philosophy',
      className: 'h2 cooper foodPhilosophy'
    },
    {
      route: 'https://www.weruva.com/tools/faq',
      text: 'Help & FAQ',
      className: 'h2 cooper helpAndFAQs'
    }
  ]
};
