import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const PageLink = (props: {
  className?: string;
  activeClassName?: string;
  to: string;
  type?: string;
  tabIndex?: number;
  focusState?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  children?: any;
}) => {
  return (
    <TransitionLink
      entry={{ delay: 0.2 }}
      exit={{ length: 1 }}
      type={props.type}
      className={props.className}
      activeClassName={props.activeClassName ?? 'active'}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      tabIndex={props.tabIndex}
      to={props.to}
    >
      {props.children}
    </TransitionLink>
  );
};

export default PageLink;
