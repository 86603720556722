const lastPathComponent = (path: string): string => {
  try {
    const segments = new URL(path).pathname.split('/');
    const lastComponent = segments.pop() || segments.pop() // Handle potential trailing slash
    return lastComponent ?? path;
  } catch (_) {
    if (path.includes('/')) {
      return path.substring(path.lastIndexOf('/') + 1);
    }
    return path;
  }
};

export default lastPathComponent;
