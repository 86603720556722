import React, { useEffect, useState } from 'react';
import { usePicostate } from '@picostate/react';
import cx from 'classnames';
import cookie from 'js-cookie';
import * as handleItems from 'src/integrations/shopify/handleShopifyLineItems';
import { CheckoutButton } from 'src/components/cart/utils/checkoutButton';
import { RenderLineItem } from 'src/components/cart/utils/renderLineItem';
import { RenderSubscriptions } from 'src/components/cart/utils/renderSubscriptions';
import { RenderUpsells } from 'src/components/cart/utils/renderUpsells';
import { RenderStarter } from 'src/components/cart/utils/renderStarter';
import store from 'src/state/store';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';
import { CartArrow, Shipping } from 'src/components/svgs/svgs';
import { trackCartView, trackRemoveFromCart } from '../analytics/analytics';
import PageLink from '../link';
import animationData from 'src/images/paw-animation.json';
import Lottie from 'react-lottie';
import { CSSTransition } from 'react-transition-group';
import { determineTypeOfStarterBox } from 'src/integrations/shopify/handleShopifyLineItems';
import { Amplitude, TrackingEvent } from 'src/utils/tracking';
import { TrackingStrings } from 'src/constants/analytics';
import { debounceCartUpdate } from 'src/integrations/shopify/checkoutExtensions';
import { useDiscountSession } from 'src/hooks/useDiscountSession';
import { SanityDiscountCode } from 'src/types/sanityTypes';
import { lookupDiscount } from 'src/integrations/recharge';
import {
  fetchStarterBoxDetails,
  lookupSanityDiscountCode
} from 'src/integrations/sanity/sanityHelper';
import { starterTypeToDiscountKey } from '../Quiz/panels/utils/offerHelper';
import { WET } from 'src/constants/texture';
import { DEFAULT_OFFER } from 'src/constants/prices';

export const FREE_SHIPPING = 50;

const toggleCart = (isCartOpen: boolean) => {
  store.hydrate({
    cartOpen: !isCartOpen
  })();
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet',
    className: 'invert'
  }
};

export const CartWidget = ({ upsells }: { upsells?: any[] }) => {
  const [state] = usePicostate();
  const [discountCodesFromSanity, setDiscountCodesFromSanity] = useState<
    SanityDiscountCode[] | null
  >(null);
  const { getDiscountResults, setAllDiscountResults } = useDiscountSession();
  const { offerId, offerPrice } = getDiscountResults();
  const [defaultOfferPrice, setDefaultOfferPrice] = useState<number>(
    DEFAULT_OFFER.OFFER_PRICE_WET_AND_DRY
  );
  const {
    shopifyCart,
    cartOpen,
    catNames
  }: {
    shopifyCart: ShopifyCart;
    catNames: Array<{ name: string }>;
    cartOpen: boolean;
  } = state;

  const [starterBoxType, setStarterBoxType] = useState();

  useEffect(() => store.hydrate({ shopifyCart })(), []);
  useEffect(() => {
    const price =
      starterBoxType === WET
        ? DEFAULT_OFFER.OFFER_PRICE_WET
        : DEFAULT_OFFER.OFFER_PRICE_WET_AND_DRY;
    setDefaultOfferPrice(price);
  }, []);
  useEffect(() => {
    if (shopifyCart) {
      if (shopifyCart.lineItems) {
        setCount(shopifyCart.lineItems.length);
      }
    }
  });

  const [quantities, setQuantities] = useState({});
  const [count, setCount] = useState(0 as number);
  const [updating, setUpdating] = useState(false);

  /*
  Keep this in state, because we'll clear both if
  we remove the starter box + meal plan, then confirm
  after the removal when the shopifyCart repopulates
  */

  const [samplerBoxItems, setSamplerBoxItems] = useState([] as ShopifyLineItem[]);
  const [subscriptionItems, setSubscriptionItems] = useState([] as ShopifyLineItem[]);

  useEffect(() => {
    setSamplerBoxItems(shopifyCart?.lineItems?.filter(handleItems.samplerBoxItems));
    setSubscriptionItems(shopifyCart?.lineItems?.filter(handleItems.subscriptionItems));
  }, [shopifyCart]);

  useEffect(() => {
    const fetchDiscountcodes = async (discountCode: string, starterType: string | undefined) => {
      const discount = await lookupDiscount({
        discountCode
      });
      const sanityStarterBox = await fetchStarterBoxDetails({ starterBoxType: starterType });
      if (discount && discount?.matchingDiscount?.value) {
        setAllDiscountResults({
          offerId: discountCode,
          offerPrice: parseFloat(sanityStarterBox.originalPrice) - discount.matchingDiscount.value,
          originalPrice: parseFloat(sanityStarterBox.originalPrice),
          discountStarterBoxType: starterType
        });
      } else {
        const discountCodesFromSanityResult: SanityDiscountCode[] = await lookupSanityDiscountCode({
          discountCode: 'DEFAULT'
        });
        setDiscountCodesFromSanity(discountCodesFromSanityResult);
      }
    };
    const applicableSanityDiscountCode = discountCodesFromSanity && discountCodesFromSanity[0];
    const starterBoxType = shopifyCart?.lineItems
      ? determineTypeOfStarterBox(shopifyCart?.lineItems)
      : undefined;
    const offerIdShopify =
      shopifyCart?.customAttributes.find((attribute) => attribute.key === 'offerId')?.value ||
      offerId ||
      'DEFAULT';
    setStarterBoxType(starterBoxType);
    if (offerIdShopify && starterBoxType) {
      fetchDiscountcodes(offerIdShopify, starterBoxType);
    } else if (applicableSanityDiscountCode && starterBoxType) {
      fetchDiscountcodes(
        starterTypeToDiscountKey(applicableSanityDiscountCode, starterBoxType),
        starterBoxType
      );
    }
  }, [shopifyCart]);

  const debouncedCartUpdate = debounceCartUpdate(setUpdating);

  useEffect(() => {
    const refreshQuantities = {};
    if (!updating && shopifyCart && shopifyCart.lineItems) {
      shopifyCart.lineItems.forEach((item) => {
        const isDiscounted = !!item?.discountAllocations.length;
        refreshQuantities[`${item.variant.id}-${isDiscounted}`] = {
          quantity: item.quantity,
          price: item.variant.price,
          lineItem: item
        };
      });
      setQuantities(refreshQuantities);
    }
  }, [shopifyCart, updating]);

  const updateCartState = ({
    e,
    lineItem,
    quantity,
    removal
  }: {
    e: React.MouseEvent;
    lineItem: ShopifyLineItem;
    quantity: number;
    removal: boolean;
  }) => {
    e.stopPropagation();

    const newQuantity = {};

    const isDiscounted = !!lineItem?.discountAllocations.length;

    newQuantity[`${lineItem.variant.id}-${isDiscounted}`] = {
      quantity,
      lineItem,
      price: lineItem.variant.price
    };

    setQuantities({ ...quantities, ...newQuantity });

    debouncedCartUpdate(newQuantity, quantities, removal);
  };

  const oneOffItems = shopifyCart?.lineItems?.filter(handleItems.oneOffItems);

  const ShopifyToday = () => {
    let cost = 0;
    if (samplerBoxItems.length > 0 && subscriptionItems.length > 0) {
      cost += offerPrice || defaultOfferPrice;
    } else if (samplerBoxItems.length > 0 && !subscriptionItems.length) {
      cost += 35;
    }

    if (oneOffItems.length > 0) {
      oneOffItems.forEach((item) => {
        const isDiscounted = !!item?.discountAllocations.length;
        const discountAmount = item.discountAllocations[0]?.allocatedAmount?.amount;
        const originalPrice =
          quantities[`${item.variant.id}-${isDiscounted}`]?.price?.amount || '0';
        const lineQuantity = quantities[`${item.variant.id}-${isDiscounted}`]?.quantity || 0;

        const linePrice = isDiscounted
          ? parseFloat(originalPrice) * lineQuantity - parseFloat(discountAmount)
          : parseFloat(originalPrice) * lineQuantity;

        cost += linePrice;
      });
    }
    return cost.toFixed(2);
  };

  useEffect(() => {
    if (cartOpen) trackCartView(shopifyCart);
  }, [cartOpen]);

  const removeStarterKitAndSubscription = (e: React.MouseEvent) => {
    e.stopPropagation();

    trackRemoveFromCart(
      {
        name: 'Starter Box + Subscription',
        id: 'Multi',
        price: offerPrice?.toFixed(2) ?? 0,
        variant: 'One'
      },
      1,
      shopifyCart
    );

    Amplitude.track(TrackingStrings.REMOVE_ITEM, {
      category: TrackingStrings.CART,
      value: 'Starter Box + Subscription'
    } as TrackingEvent);

    const itemsToRemove = [
      ...samplerBoxItems.map((lineItem) => ({
        ...lineItem,
        /* Our cart separates discounted and non-discounted items
        for removal, and due to an discount Shopify script
        will not be removed unless we pass it to our with a
        discount by default. */
        ...{ discountAllocations: [1] }
      })),
      ...subscriptionItems
    ];

    itemsToRemove.forEach((lineItem) => {
      updateCartState({
        e,
        // @ts-ignore
        lineItem,
        quantity: 0,
        removal: true
      });
    });

    setSamplerBoxItems([]);
    setSubscriptionItems([]);
  };

  let cats: Array<{ name: string }>;

  if (catNames) {
    cats = catNames;
  } else {
    try {
      cats = JSON.parse(cookie.get('cat_names') as string) as Array<{
        name: string;
      }>;
    } catch (err) {
      cats = [];
    }
  }

  const trap = cartOpen ? (
    <div
      className="cart__widget-inner p1 x bcb rel z3 f col jcb"
      onClick={(e) => e.stopPropagation()}
    >
      {/* @ts-ignore */}
      <CSSTransition in={updating} timeout={250} className="loading-paw__transition">
        <div>
          {/* @ts-ignore */}
          <Lottie
            options={defaultOptions}
            height={'25%'}
            width={'25%'}
            isStopped={false}
            isPaused={false}
          />
        </div>
      </CSSTransition>
      <div className="f col cart__widget-header ">
        <div className="rel ac">
          <button
            aria-label="close cart"
            className="close-button abs left top py1 remove--style cw"
            onClick={() => {
              toggleCart(cartOpen);
            }}
          >
            <CartArrow />
          </button>
          <h5 className="caps sans mt0 s13 mb05 py05 ls">Your Cart</h5>
        </div>
      </div>
      <div className="f col cart__widget-product-list">
        {count === 0 && (
          <React.Fragment>
            <div className="f cart__widget-empty abs jcc aic ac x">
              <h2 className="sans-b">
                No cats
                <br />
                <span className="cooper">in this bag.</span>
              </h2>
            </div>
            <div>
              <PageLink
                to="/products"
                onClick={() => toggleCart(cartOpen)}
                className="button-dark-outline abs bottom cart__widget-continue button--white ac caps sans cw"
              >
                Take me Shopping
              </PageLink>
            </div>
          </React.Fragment>
        )}
        {oneOffItems?.map((item, index) => {
          const isDiscounted = !!item?.discountAllocations.length;

          return quantities[`${item.variant.id}-${isDiscounted}`] ? (
            <RenderLineItem
              item={item}
              quantities={quantities}
              updateCartState={updateCartState}
              shopifyCart={shopifyCart}
              key={`${item?.title}_${index}`}
            />
          ) : null;
        })}
        {samplerBoxItems && samplerBoxItems.length > 0 ? (
          <React.Fragment>
            <RenderStarter
              handleRemoval={removeStarterKitAndSubscription}
              subscriptionItems={subscriptionItems}
              samplerBoxOfferPrice={offerPrice || defaultOfferPrice}
            />
          </React.Fragment>
        ) : null}
        {subscriptionItems && subscriptionItems.length > 0 && (
          <RenderSubscriptions items={subscriptionItems} names={cats} />
        )}
      </div>
      {count !== 0 && (
        <div className="f col jcc aic bcb cart__widget-fixed-container-at-bottom">
          {count !== 0 && (
            <React.Fragment>
              <div className="f row jcb x ais mt1 mb1 cart__widget-subtotal">
                <div>
                  <h5 className="sans-b p0 m0 s16">Today's Subtotal</h5>
                </div>
                <div>
                  <span className="s16 sans-b">${ShopifyToday()}</span>
                </div>
              </div>
            </React.Fragment>
          )}
          <CheckoutButton
            samplerBoxItems={samplerBoxItems}
            oneOffItems={oneOffItems}
            subscriptionItems={subscriptionItems}
            shopifyCart={shopifyCart}
            webUrl={shopifyCart && shopifyCart.webUrl}
          />
          {upsells && <RenderUpsells shopifyCart={shopifyCart} cartUpsells={upsells} />}
        </div>
      )}
    </div>
  ) : (
    false
  );

  return (
    <div
      onClick={() => {
        toggleCart(cartOpen);
      }}
      className={cx('cart__widget bcb z9 cw', cartOpen ? 'open' : 'closed')}
      id="container"
    >
      {trap}
    </div>
  );
};
