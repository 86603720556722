import React, { useState, useMemo, useEffect } from 'react';
import { encode } from 'shopify-gid';
import {
  GTMProduct,
  trackAddToCart,
  trackCrossSell,
  trackProductImpression
} from 'src/components/analytics/analytics';
import { pullAllBy, cloneDeep } from 'lodash';
import * as checkout from 'src/integrations/shopify/checkout';
import { Image } from 'src/components/Image';
import { ShopifyCart } from 'src/types/shopifyTypes';
import { Amplitude, TrackingEvent } from 'src/utils/tracking';
import { TrackingStrings } from 'src/constants/analytics';
import lastPathComponent from 'src/utils/lastPathComponent';

export const RenderUpsells = ({
  shopifyCart,
  cartUpsells
}: {
  shopifyCart: ShopifyCart | undefined;
  cartUpsells: any[];
}) => {
  const upsellClone = cloneDeep(cartUpsells);

  const [adding, setAdding] = useState(false);

  const upSellProduct = useMemo(() => {
    const cartProductIds = [] as any[];
    shopifyCart?.lineItems.forEach((cartItem) => {
      const cartIdItem = {
        productId: parseInt(lastPathComponent(cartItem.variant.product.id), 0)
      };
      cartProductIds.push(cartIdItem);
    });

    const remainingItems = pullAllBy(upsellClone, cartProductIds, 'productId');

    return remainingItems.length > 0 ? remainingItems[0] : null;
  }, [shopifyCart, cartUpsells]);

  const tagManagerProductInformation =
    upSellProduct &&
    ({
      name: upSellProduct.title,
      id: upSellProduct.sku,
      price: parseFloat(upSellProduct?.price || ''),
      variant: upSellProduct?.variantTitle
    } as GTMProduct);

  useEffect(() => {
    if (upSellProduct) trackProductImpression([tagManagerProductInformation], 'In-Cart Cross Sell');
  }, []);

  const handleSubmit = (_: React.FormEvent) => {
    setAdding(true);

    const shopifyId = encode('ProductVariant', upSellProduct.variantId);

    // @ts-ignore
    checkout
      .add({
        variantId: shopifyId,
        quantity: 1
      } as {
        variantId: string;
        quantity: number;
      })
      .then(() => {
        if (shopifyCart) {
          trackAddToCart(tagManagerProductInformation, 1, shopifyCart);
          trackCrossSell('in_cart', tagManagerProductInformation);

          Amplitude.track(TrackingStrings.ADD_UPSELL, {
            category: TrackingStrings.CART,
            value: tagManagerProductInformation.name
          } as TrackingEvent);
        }
        setAdding(false);
      });
  };

  return (
    <div className="x">
      {upSellProduct && (
        <div className="cart__widget-upsell mt1 f jcb ais">
          <div className="cart__widget-upsell-img x">
            <Image
              className="x"
              imageId={upSellProduct.variantImage.asset._id}
              alt={upSellProduct.variantImage.alt}
            />
          </div>
          <div className="cart__widget-upsell-text">
            <div>
              <span className="s16 sans-b block">{upSellProduct.variantUpsellTitle}</span>
            </div>
            <span className="serif">
              {upSellProduct.variantCleanTitle} {upSellProduct.title}
            </span>
            <br />
            <span className="s11">{upSellProduct.variantSubTitle}</span>
          </div>
          <div className="ar">
            <button
              onClick={(e) => handleSubmit(e)}
              className="cw strong caps s12 ls sans bold p0 m0 nowrap underline"
            >
              {adding ? 'Adding' : 'Add +'}
            </button>
            <br />
            <span className="s11 ls2">${upSellProduct.price}</span>
          </div>
        </div>
      )}
    </div>
  );
};
