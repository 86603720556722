import cookie from 'js-cookie';
// @ts-ignore
import { connect } from '@picostate/react';
import cx from 'classnames';
import React, { useState, useEffect, useRef, RefObject } from 'react';

import { ShopifyCart } from 'src/types/shopifyTypes';

import { Bowl, Logo } from 'src/components/svgs/svgs';
import PageLink from 'src/components/link';

import store from 'src/state/store';
import { BannerBar } from '../modules/bannerBar';
import { deriveCartQuantity } from './cart/utils/deriveCartQuantity';

interface Props {
  cartOpen: boolean;
  shopOpen: boolean;
  aboutOpen: boolean;
  navHide?: boolean;
  customerToken: string;
  shopifyCart: ShopifyCart;
  navOpen: boolean;
  firstName: string;
  pageContext: {
    bannerBar: any;
    slug?: { current?: string };
  };
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
}

const toggleCart = (isCartOpen: boolean, _: ShopifyCart) => {
  store.hydrate({
    cartOpen: !isCartOpen,
    navOpen: false,
    shopOpen: false,
    aboutOpen: false
  })();
};

const closeAll = () => {
  store.hydrate({
    cartOpen: false,
    navOpen: false,
    shopOpen: false
  })();
};

const toggleNav = (isNavOpen: boolean) => {
  store.hydrate({
    navOpen: !isNavOpen
  })();
};

const toggleShopMenu = (e: React.MouseEvent, isShopOpen: boolean) => {
  e.preventDefault();
  store.hydrate({
    shopOpen: !isShopOpen
  })();
};

export const Header = connect(
  (state: {
    customerToken?: string;
    cartOpen?: boolean;
    navOpen?: boolean;
    navHide?: boolean;
    shopifyCart: ShopifyCart;
    shopOpen?: boolean;
    aboutOpen?: boolean;
    firstName: string;
  }) => ({
    customerToken: state.customerToken,
    cartOpen: state.cartOpen,
    shopOpen: state.shopOpen,
    navHide: state.navHide,
    shopifyCart: state.shopifyCart,
    aboutOpen: state.aboutOpen,
    navOpen: state.navOpen,
    firstName: state.firstName
  })
)(
  ({
    customerToken,
    cartOpen,
    navOpen,
    navHide,
    shopifyCart,
    shopOpen,
    firstName,
    pageContext,
    setHeaderHeight
  }: Props) => {
    //   useEffect(() => {
    //     setCount(deriveCartQuantity(shopifyCart));
    //   });
    //   const loggedIn = customerToken || cookie.get('customer_token');
    //   const [count, setCount] = useState(0 as number);
    //   const { slug } = pageContext;
    //   console.log(slug?.current);
    //   const showHeader = !['quiz', 'quiz_revamp'].includes(slug?.current || '');
    //   const showBannerBar =
    //     pageContext._type !== 'product' &&
    //     !['wet-food', 'dry-food', 'treats', 'toys', 'accessories', 'apparel', 'products'].includes(
    //       slug?.current || ''
    //     );
    //   const stickyHeader = !['meal-plan'].includes(slug?.current || '');
    //   /* All the following relates to the stick-to-the-top banner bar */
    //   let { bannerBar } = pageContext;
    //   if (!stickyHeader) bannerBar = {};
    //   const bannerRef = useRef(null) as null | RefObject<HTMLDivElement>;
    //   const headerRef = useRef(null) as null | RefObject<HTMLDivElement>;
    //   const [bannerHidden, setBannerHidden] = useState(false);
    //   const [bannerHeight, setBannerHeight] = useState(0);
    //   const onScroll = () => {
    //     if (!bannerRef || !bannerRef?.current) return;
    //     if (bannerRef && headerRef?.current) {
    //       const bannerRectHeight = bannerRef?.current?.getBoundingClientRect().height;
    //       setBannerHeight(bannerRectHeight);
    //       const headerRectHeight = headerRef?.current?.getBoundingClientRect().height;
    //       // Used to calculate nav spacers elsewhere
    //       const calculatedHeaderHeight = bannerHidden
    //         ? headerRectHeight - bannerRectHeight
    //         : headerRectHeight;
    //       setHeaderHeight(calculatedHeaderHeight - 18);
    //       //console.info({ calculatedHeaderHeight })
    //       const scrollTop =
    //         window.pageYOffset !== undefined
    //           ? window.pageYOffset
    //           : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    //       if (calculatedHeaderHeight && scrollTop > bannerRectHeight) {
    //         setBannerHidden(true);
    //       } else {
    //         setBannerHidden(false);
    //       }
    //     }
    //   };
    //   // TODO: this screws up the header height locally / overrides it with like 123px and
    //   // makes the spacing weird
    //   useEffect(() => {
    //     window.addEventListener('scroll', onScroll);
    //     onScroll();
    //     return () => {
    //       window.removeEventListener('scroll', onScroll);
    //     };
    //   }, [bannerRef]);
    //   return showHeader ? (
    //     <React.Fragment>
    //       <header
    //         className={cx('ac header x fix top left bcw z7 overflow-x-hidden', {
    //           // these two classes are deprecated, from the original implementation
    //           'scroll-away': navHide,
    //           'always-show': navOpen
    //         })}
    //         ref={headerRef}
    //         style={{
    //           // shopOpen: desktop nav open, navOpen: mobile nav open
    //           transform:
    //             (bannerHidden || shopOpen || navOpen) && !(!stickyHeader && (shopOpen || navOpen))
    //               ? `translateY(-${stickyHeader ? `${bannerHeight}px` : `100%`})`
    //               : 'translateY(0px)',
    //           transition: 'transform 100ms ease-out 0s'
    //         }}
    //       >
    //         {showBannerBar && (
    //           <BannerBar
    //             {...bannerBar}
    //             bannerRef={bannerRef}
    //             style={{
    //               transform:
    //                 (bannerHidden || shopOpen || navOpen) && !(!stickyHeader && (shopOpen || navOpen))
    //                   ? `translateY(-${bannerHeight}px)`
    //                   : 'translateY(0px)',
    //               transition: 'transform 100ms ease-out 0s'
    //             }}
    //           />
    //         )}
    //         <div className="ba header__inner p1 outer f aic">
    //           <div className="x f jcb aic">
    //             <div className="header__mobile-toggle s-mobile">
    //               <a href="#menu" onClick={() => toggleNav(navOpen)}>
    //                 {navOpen ? 'Close' : 'Menu'}
    //               </a>
    //             </div>
    //             <div className="header__desktop s-desktop">
    //               <PageLink
    //                 to="https://www.weruva.com/collections/cat-person"
    //                 onClick={closeAll}
    //                 className={cx('mr1 s14 ls-ss m0 p0 underline-nav-item', {
    //                   active: !shopOpen && slug?.current === 'food-philosophy'
    //                 })}
    //                 activeClassName=""
    //               >
    //                 Learn More
    //               </PageLink>
    //               <button
    //                 onClick={(e) => toggleShopMenu(e, shopOpen)}
    //                 className={cx('mr1 s14 ls-ss m0 p0 underline-nav-item', {
    //                   active: shopOpen
    //                 })}
    //               >
    //                 Shop Products
    //               </button>
    //             </div>
    //             <PageLink to="/" onClick={closeAll} className="p05 header__logo abs">
    //               <Logo />
    //             </PageLink>
    //             <div className="f jcb aic">
    //               {loggedIn ? (
    //                 <div>
    //                   <a href="/account" className="mr1 s-desktop underline-hover s14 ls-ss">
    //                     Hi, {firstName}
    //                   </a>
    //                   {/*<a href="#"
    //                   className="mr1s-desktop" onClick={logout}>Logout</a>*/}
    //                 </div>
    //               ) : (
    //                 <div className="f jcc aic">
    //                   <a href="/account" className="mr1 s-desktop underline-hover s14 ls-ss">
    //                     Log in
    //                   </a>
    //                   {/*<a href="/account/register"
    //                   className="mr1 s-desktop underline">Register</a>*/}
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </header>
    //     </React.Fragment>
    //   ) : null;
  }
);
