import React from 'react';
// @ts-ignore
import PageLink from 'src/components/link';
import { TrackingStrings } from 'src/constants/analytics';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';
import { Amplitude, TrackingEvent } from 'src/utils/tracking';
import { useDiscountSession } from 'src/hooks/useDiscountSession';

export const CheckoutButton = ({
  samplerBoxItems,
  oneOffItems,
  subscriptionItems,
  webUrl,
  shopifyCart
}: {
  shopifyCart: ShopifyCart | undefined;
  samplerBoxItems: ShopifyLineItem[] | undefined;
  oneOffItems: ShopifyLineItem[] | undefined;
  subscriptionItems: ShopifyLineItem[] | undefined;
  webUrl: string | undefined;
}) => {
  const { setAllDiscountResults } = useDiscountSession();

  const trackCheckoutClick = () => {
    Amplitude.track(TrackingStrings.CHECKOUT, {
      category: TrackingStrings.CART
    } as TrackingEvent);
    const shopifyOfferId = shopifyCart?.customAttributes.find(
      (attribute) => attribute.key === 'offerId'
    )?.value;
    const shopifyOfferPrice = shopifyCart?.customAttributes.find(
      (attribute) => attribute.key === 'offerPrice'
    )?.value;
    const shopifyOriginalPrice = shopifyCart?.customAttributes.find(
      (attribute) => attribute.key === 'originalPrice'
    )?.value;
    const starterBoxtype = shopifyCart?.customAttributes.find(
      (attribute) => attribute.key === 'starterBoxtype'
    )?.value;
    if (shopifyOfferId && shopifyOfferPrice && shopifyOriginalPrice && starterBoxtype) {
      setAllDiscountResults({
        offerId: shopifyOfferId,
        offerPrice: parseFloat(shopifyOfferPrice),
        originalPrice: parseFloat(shopifyOriginalPrice),
        discountStarterBoxType: starterBoxtype
      })
    }
  };

  if (subscriptionItems?.length) {
    return (
      <PageLink to="/checkout/" className="f caps jcb aic" onClick={trackCheckoutClick}>
        <button
          disabled={!samplerBoxItems?.length && !oneOffItems?.length && !subscriptionItems?.length}
          className="button__cta caps m1"
        >
          Checkout
        </button>
      </PageLink>
    );
  } else if (oneOffItems?.length || samplerBoxItems?.length) {
    return (
      <a href={webUrl} className="f x caps jcb aic">
        <button
          disabled={!samplerBoxItems?.length && !oneOffItems?.length && !subscriptionItems?.length}
          className="button__cta caps x mb1 mt05"
          onClick={trackCheckoutClick}
        >
          Checkout
        </button>
      </a>
    );
  } else {
    return (
      <div className="f x jcb caps aic">
        <button
          disabled={!samplerBoxItems?.length && !oneOffItems?.length && !subscriptionItems?.length}
          className="button__cta caps x mb1 mt05"
        >
          Add Items To Checkout
        </button>
      </div>
    );
  }
};
