import { DRY, WET, WET_DRY } from 'src/constants/texture';
import { TrackingStrings } from '../../../../constants/analytics';

export const stringToEventCategory = (starterType?: string) => {
  switch (starterType) {
    case WET:
      return TrackingStrings.EVENT_CATEGORY_WET_ONLY_QUIZ;
    case WET_DRY:
      return TrackingStrings.EVENT_CATEGORY_DRY_AND_WET_QUIZ;
    case DRY:
      return TrackingStrings.EVENT_CATEGORY_DRY_ONLY_QUIZ;
    default:
      return TrackingStrings.EVENT_CATEGORY_UNSET_STARTER_TYPE;
  }
};

export const stringsToHyphenedStrings =
  (string1: string = '') =>
  (string2: string = '') =>
  (string3: string = ''): string => {
    const hasThirdString: boolean = string3?.length > 0;
    if (hasThirdString) {
      return `${string1} - ${string2} - ${string3}`;
    }
    return `${string1} - ${string2}`;
  };

export const isStringAndStringEqual =
  (string1: string = '') =>
  (string2: string = ''): boolean =>
    string1 === string2;

export const isStringAndStringEqualIgnoreCase =
  (string1: string = '') =>
  (string2: string = ''): boolean =>
    string1.toLocaleLowerCase() === string2.toLocaleLowerCase();

export const isNumberAndNumberEqual =
  (number1 = 0) =>
  (number2 = 0): boolean =>
    number1 === number2;

export const stringsToStringWithUnderscores =
  (string1: string = '') =>
  (string2: string = '') =>
  (string3: string = ''): string => {
    const hasThirdString: boolean = string3?.length > 0;
    if (hasThirdString) {
      return `${string1}_${string2}_${string3}`;
    }
    return `${string1}_${string2}`;
  };
