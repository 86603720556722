/* This are a la carte prices */
export const DRY_PRICE = 14.5;
export const WET_PRICE = 9.5;
export const GOODNESS_PRICE = 19.5;
export const SERVE_AND_STORE_SET_PRICE = 12;

export const DEFAULT_OFFER = {
  OFFER_PRICE_WET_AND_DRY: 25,
  OFFER_PRICE_WET: 28,
  DISCOUNT_OFF_RETAIL_WET: 10,
  DISCOUNT_OFF_RETAIL_WET_AND_DRY: 8.5,
  DISCOUNT_CODE_WET: 'WET_STARTER_BOX_28',
  DISCOUNT_CODE_WET_AND_DRY: 'WET_DRY_STARTER_BOX_25',
  DISCOUNT_CODE_DRY: 'DRY_STARTER_BOX_21'
} as const;
