import { navigate } from 'gatsby-link';
import cookie from 'js-cookie';
import { isAbsolute } from 'path';
import spacetime, { Spacetime } from 'spacetime';
import { isApplePay, isPaypal, PaymentMethod } from 'src/components/checkout/context';

import { ShopifyLineItem } from 'src/types/shopifyTypes';
import { AddressInformation } from 'src/types/types';
import store from '../state/store';
import {
  RechargeAddress,
  RechargeCheckoutResponse,
  RechargeCustomer,
  RechargeDiscount,
  RechargeOnetimeItem,
  RechargePortalPaymentMethod,
  RechargeProcessedCheckout,
  RechargeSubscriptionItem,
  SubscriptionStatus,
  RechargeCustomerCheck,
  RechargeCharge
} from 'src/types/rechargeTypes';

import {
  convertShopifyItemToRechargeItem,
  SimpleRechargeItem
} from './recharge/convertShopifyItemToRechargeItem';

import { handleRechargeAPIError } from './recharge/errorHandling';
import lastPathComponent from 'src/utils/lastPathComponent';

const GATSBY_API_URL = process.env.GATSBY_API_URL;
const SERVERLESS_API_KEY = process.env.GATSBY_SERVERLESS_API_KEY || '';

const SERVERLESS_HEADER = {
  'X-API-KEY': SERVERLESS_API_KEY
};

export const redirectUnauthenticatedUsers = (res: any): any | void => {
  if (res.customer === null) {
    cookie.remove('customer_email');
    cookie.remove('customer_token');
    cookie.remove('customer_data');
    navigate('/account/login').then();
  }
  return res;
};

/*

  CreateCheckout: create a new recharge "checkout" object, which we can manipulate
  and ultimate charge ("process")

 */

type CreateCheckout = ({
  shippingAddress,
  email,
  phone,
  lineItems,
  discountCode,
  noteAttributes
}: {
  shippingAddress: RechargeAddress;
  email: string;
  phone: string;
  lineItems: ShopifyLineItem[];
  discountCode?: string;
  noteAttributes?: Array<{ name: string; value: string }>;
}) => Promise<RechargeCheckoutResponse | void>;

export const createCheckout: CreateCheckout = async ({
  shippingAddress,
  email,
  phone,
  lineItems,
  discountCode,
  noteAttributes
}) => {
  const FXN_NAME = '[recharge: createCheckout]';

  const lineItemsToSubmitToRecharge = lineItems.map(convertShopifyItemToRechargeItem);

  const body = {
    checkout: {
      email,
      phone,
      shipping_address: shippingAddress,
      line_items: lineItemsToSubmitToRecharge,
      discount_code: discountCode,
      note_attributes: noteAttributes
    }
  };

  return await fetch(`${GATSBY_API_URL}/recharge/checkout-create`, {
    method: 'POST',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        rechargeCart: res
      })();

      return res as RechargeCheckoutResponse;
    });
};

type CalculateTax = ({
  zipCode,
  city,
  state,
  country,
  lineItems,
  discountCode
}: {
  zipCode: string;
  city: string;
  state: string;
  country: string;
  lineItems: ShopifyLineItem[];
  discountCode?: string;
}) => Promise<RechargeCheckoutResponse | void>;

// Returns a checkout object with tax amounts based on basic geographical area
export const calculateTax: CalculateTax = async ({
  zipCode,
  city,
  state,
  country,
  lineItems,
  discountCode
}) => {
  const FXN_NAME = '[recharge: calculateTax]';

  const lineItemsToSubmitToRecharge = lineItems.map(convertShopifyItemToRechargeItem);

  const body = {
    checkout: {
      email: 'none@email.com',
      shipping_address: {
        last_name: 'None',
        address1: '1', // Recharge requires at least one char
        city: city,
        province: state,
        zip: zipCode,
        country: country
      },
      line_items: lineItemsToSubmitToRecharge,
      discount_code: discountCode
    }
  };

  return await fetch(`${GATSBY_API_URL}/recharge/checkout-create`, {
    method: 'POST',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then((res) => {
      return res as RechargeCheckoutResponse;
    });
};

/*

  Apply Discount

 */

type ApplyDiscount = ({
  checkoutResponse,
  discountCode
}: {
  checkoutResponse: RechargeCheckoutResponse;
  discountCode?: string;
}) => Promise<RechargeCheckoutResponse | void>;

export const applyDiscount: ApplyDiscount = async ({ checkoutResponse, discountCode }) => {
  const FXN_NAME = '[recharge: updateCheckout]';

  const body = {
    token: checkoutResponse?.token,
    checkout: {
      discount_code: discountCode
    }
  };

  console.info('Attempting to update checkout with', { body });
  return await fetch(`${GATSBY_API_URL}/recharge/checkout-update`, {
    method: 'PUT',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      console.info('Attempted to apply discount, now hydrating store with updated recharge cart.');
      store.hydrate({
        rechargeCart: res.checkout
      })();
      return res.checkout as RechargeCheckoutResponse;
    });
};

enum NewRechargePaymentType {
  PAYPAL = 'PAYPAL',
  APPLEPAY = 'APPLE_PAY',
  CREDIT_CARD = 'CREDIT_CARD'
}

interface RechargeCheckoutBody {
  checkout_token: string;
  payment_processor: string;
  payment_token: string;
  payment_type: NewRechargePaymentType;
}

type ProcessCheckoutClick = ({
  paymentProcessor,
  paymentToken,
  checkoutToken
}: {
  checkoutToken: string;
  paymentProcessor: string;
  paymentToken: string;
  paymentMethod: PaymentMethod;
}) => Promise<RechargeProcessedCheckout | void>;

export const processCheckoutClick: ProcessCheckoutClick = async ({
  paymentProcessor: payment_processor,
  paymentToken: payment_token,
  checkoutToken: checkout_token,
  paymentMethod
}) => {
  const FXN_NAME = '[recharge: processCheckoutClick]';

  const body: RechargeCheckoutBody = {
    checkout_token,
    payment_processor,
    payment_token,
    payment_type: isPaypal(paymentMethod)
      ? NewRechargePaymentType.PAYPAL
      : isApplePay(paymentMethod)
      ? NewRechargePaymentType.APPLEPAY
      : NewRechargePaymentType.CREDIT_CARD
  };

  return await fetch(`${GATSBY_API_URL}/recharge/checkout-process`, {
    method: 'POST',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        processedRechargeCheckout: res
      })();
      return res;
    })
    .then((res) => res as RechargeProcessedCheckout);
};

/*

  Retrieve Customer: get a recharge customer from a given email.

 */

type RetrieveCustomer = ({
  email,
  accessToken
}: {
  email: string;
  accessToken: string;
}) => Promise<RechargeCustomer | void>;

export const retrieveRechargeCustomerRecord: RetrieveCustomer = async ({ email, accessToken }) => {
  const FXN_NAME = '[recharge: retrieveCustomer]';

  if (!(email && accessToken)) {
    return undefined;
  } else {
    const url = `${GATSBY_API_URL}/recharge/customer-retrieve?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`;

    return await fetch(url, {
      method: 'GET',
      headers: SERVERLESS_HEADER
    })
      .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
      .then(redirectUnauthenticatedUsers)
      .then((res) => {
        store.hydrate({
          rechargeCustomer: res?.customer
        })();
        return res;
      })
      .then((res) => res?.customer as RechargeCustomer);
  }
};

/*

  Check Customer: get a recharge customer from a given email.

 */

type CheckCustomer = ({ email }: { email: string }) => Promise<RechargeCustomerCheck | void>;

export const checkRechargeCustomerRecord: CheckCustomer = async ({ email }) => {
  const FXN_NAME = '[recharge: checkCustomer]';

  if (!email) {
    console.info(`Email is null returning undefined`);
    return undefined;
  } else {
    const url = `${GATSBY_API_URL}/recharge/customer-check?email=${encodeURIComponent(email)}`;

    return await fetch(url, {
      method: 'GET',
      headers: SERVERLESS_HEADER
    })
      .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
      .then(redirectUnauthenticatedUsers)
      .then((res) => {
        store.hydrate({
          rechargeCustomer: res?.customer
        })();
        return res;
      })
      .then((res) => res?.customer as RechargeCustomerCheck);
  }
};

/*

  Create the address for our customer, before adding subscriptions. (Does not currently
  support multiple addresses).

 */

type CreateAddress = ({
  customer,
  email,
  address
}: {
  customer: RechargeCustomer;
  email: string;
  address: AddressInformation;
}) => Promise<RechargeAddress | void>;

export const createAddress: CreateAddress = async ({ address, customer, email }) => {
  const FXN_NAME = '[recharge: createAddress]';

  const body = {
    customer,
    address
  };

  return await fetch(
    `${GATSBY_API_URL}/recharge/address-create?email=${encodeURIComponent(email)}`,
    {
      method: 'POST',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        rechargeAddress: res
      })();
      return res;
    })
    .then((res) => res.address as RechargeAddress);
};

/*

  Retrieve an address by a customer ID.

 */

type RetrieveAddressByCustomerId = (
  customerId: string | number,
  email: string,
  accessToken: string
) => Promise<RechargeAddress | undefined>;

export const retrieveAddressByCustomerId: RetrieveAddressByCustomerId = async (
  customerId,
  email,
  accessToken
) => {
  const FXN_NAME = '[recharge: retrieveAddressByCustomerId]';

  if (!customerId) throw new Error(`${FXN_NAME} error: no customer id provided.`);

  return await fetch(
    `${GATSBY_API_URL}/recharge/address-retrieve?customerId=${customerId}&email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        address: res.address
      })();
      return res.address as RechargeAddress;
    });
};

/*

  Update a given customers subscription address.

 */

type UpdateSubscriptionAddress = (
  updatedAddress: RechargeAddress,
  subscriptions: RechargeSubscriptionItem[],
  email: string,
  accessToken: string,
  addressId?: number
) => Promise<RechargeAddress | void>;

export const updateSubscriptionAddress: UpdateSubscriptionAddress = async (
  updatedAddress,
  subscriptions,
  email,
  accessToken,
  addressId
) => {
  const FXN_NAME = '[recharge: updateSubscriptionAddress';
  const body = {
    address: {
      id: addressId || subscriptions[0].address_id,
      ...updatedAddress
    }
  };

  return await fetch(
    `${GATSBY_API_URL}/recharge/address-update?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'PUT',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        address: res.address
      })();
      return res.address as RechargeAddress;
    });
};

/*

  Update a given customers Billing address.

 */

export const updateCustomerBillingAddress = async ({
  customerId,
  billingAddress,
  email,
  accessToken
}: {
  customerId: number;
  billingAddress: object;
  email: string;
  accessToken: string;
}) => {
  const FXN_NAME = '[recharge: updateSubscriptionAddress';
  const body = {
    rechargeCustomerId: customerId,
    customer: billingAddress
  };

  return await fetch(
    `${GATSBY_API_URL}/recharge/customer-update?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'PUT',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        address: res.address
      })();
      return res.address as RechargeAddress;
    });
};

/*

  Update the next_charge_scheduled_at for all a user's subscriptions.

 */

/*

  Retrieve a user's subscriptions.

 */

type RetrieveOneTimes = (
  email: string,
  accessToken: string
) => Promise<{
  email: string;
  onetimes: RechargeOnetimeItem[];
  shipmentDate: Spacetime | undefined;
  addressId: number | undefined;
}>;

// @ts-ignore
export const retrieveOnetimes: RetrieveOneTimes = async (email, accessToken) => {
  const FXN_NAME = '[recharge: retrieveOnetimes]';

  if (!(email && accessToken)) {
    return [];
  }

  return await fetch(
    `${GATSBY_API_URL}/recharge/onetimes-retrieve?accessToken=${accessToken}&email=${encodeURIComponent(
      email
    )}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      const responseData = {
        email,
        onetimes: res.onetimes,
        shipmentDate: res.onetimes[0]
          ? spacetime(res.onetimes[0].next_charge_scheduled_at)
          : undefined,
        addressId: res.onetimes[0] ? res.onetimes[0].address_id : undefined
      };

      store.hydrate(responseData)();
      return responseData;
    });
};

type RetrieveSubscriptions = (
  email: string,
  accessToken: string
) => Promise<{
  email: string;
  subscriptions: RechargeSubscriptionItem[];
  onetimes: RechargeOnetimeItem[];
  shipmentDate: Spacetime | undefined;
  addressId: number | undefined;
}>;

// @ts-ignore
export const retrieveSubscriptions: RetrieveSubscriptions = async (email, accessToken) => {
  const FXN_NAME = '[recharge: retrieveSubscriptions]';

  if (!email) {
    return [];
  }

  return await fetch(
    `${GATSBY_API_URL}/recharge/subscriptions-retrieve?email=${encodeURIComponent(email)}&status=${
      SubscriptionStatus.active
    }&accessToken=${accessToken}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      const responseData = {
        email,
        subscriptions: res.subscriptions,
        onetimes: res.onetimes,
        shipmentDate: res.subscriptions[0]
          ? spacetime(res.subscriptions[0].next_charge_scheduled_at)
          : undefined,
        addressId: res.subscriptions[0] ? res.subscriptions[0].address_id : undefined
      };

      store.hydrate(responseData)();
      return responseData;
    });
};

/*

  Create subscriptions for a user.

 */

type CreateSubscriptions = ({
  subscriptionQuantities,
  shipmentDate,
  addressId,
  message,
  customerId
}: {
  subscriptionQuantities: SimpleRechargeItem[];
  shipmentDate: Spacetime;
  addressId: number;
  message: string;
  customerId: string | undefined;
}) => Promise<Array<{ status: number; statusText: string }> | void>;

export const createSubscriptions: CreateSubscriptions = async ({
  subscriptionQuantities,
  shipmentDate,
  addressId,
  message,
  customerId
}) => {
  const FXN_NAME = '[recharge: createSubscriptions]';

  const body = {
    subscriptionQuantities,
    shipmentDate: shipmentDate ? shipmentDate.unixFmt('yyyy-MM-dd') : undefined,
    addressId,
    message,
    customerId
  };

  return await fetch(`${GATSBY_API_URL}/recharge/subscriptions-create`, {
    method: 'POST',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res.subscriptions as Array<{ status: number; statusText: string }>;
    });
};

/*

  Update subscription quantities and etc.

 */

/*

  Cancel all subscriptions.

 */

export const cancelAllSubscriptions = async ({
  subscriptions,
  onetime_ids_to_cancel,
  selectedCancellationReason,
  email,
  accessToken
}: {
  subscriptions: RechargeSubscriptionItem[];
  onetime_ids_to_cancel: RechargeOnetimeItem[];
  selectedCancellationReason: string;
  email: string;
  accessToken: string;
}) => {
  const FXN_NAME = '[cancel all subscriptions]';

  const subsciptionIdsToCancel = subscriptions.map((item) => item.id) as number[];

  const body = {
    subscription_ids_to_cancel: subsciptionIdsToCancel,
    onetime_ids_to_cancel,
    cancellation_reason: selectedCancellationReason
  };

  return await fetch(
    `${GATSBY_API_URL}/recharge/subscriptions-cancel?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'POST',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res.subscriptions as Array<{ status: number; statusText: string }>;
    });
};

/*

  Add One-time Add-on.

 */

export const createOnetimeAddon = async ({
  onetime,
  addressId,
  email,
  accessToken,
  discountCode,
  properties,
  price
}: {
  onetime: object;
  addressId: string;
  email: string;
  accessToken: string;
  discountCode: string | null;
  properties: object | null;
  price: string | undefined;
}) => {
  const body = {
    addressId: addressId,
    onetime,
    code: discountCode,
    properties,
    price
  };
  return await fetch(
    `${GATSBY_API_URL}/recharge/onetimes-create?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'POST',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res;
    });
};

/*

  update One-time Add-on.

 */
export const updateOneTimeAddon = async ({
  onetimes,
  email,
  accessToken
}: {
  onetimes: object;
  email: string;
  accessToken: string;
}) => {
  const FXN_NAME = '[recharge: updateOneTimeAddon]';
  const updateAddon: any = [];

  for (const key in onetimes) {
    if (onetimes[key].quantity > 0) {
      updateAddon.push({ id: key, quantity: onetimes[key].quantity });
    }
  }
  const body = {
    onetimes: updateAddon
  };
  return await fetch(
    `${GATSBY_API_URL}/recharge/onetimes-update?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'PUT',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify(body)
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res;
    });
};

/*

  Remove One-time Add-on.

 */
export const removeOneTimeAddon = async ({
  onetimes,
  email,
  accessToken
}: {
  onetimes: object;
  email: string;
  accessToken: string;
}) => {
  const FXN_NAME = '[recharge: removeOneTimeAddon]';
  const removeAddon: any = [];
  for (const key in onetimes) {
    if (onetimes[key].quantity == 0) {
      removeAddon.push(key);
    }
  }
  const body = {
    onetimeIds: removeAddon
  };
  if (removeAddon.length > 0) {
    return await fetch(
      `${GATSBY_API_URL}/recharge/onetimes-remove?email=${encodeURIComponent(
        email
      )}&accessToken=${accessToken}`,
      {
        method: 'POST',
        headers: SERVERLESS_HEADER,
        body: JSON.stringify(body)
      }
    )
      .then((res) => handleRechargeAPIError(res, FXN_NAME, body))
      .then(redirectUnauthenticatedUsers)
      .then((res) => {
        return res;
      });
  } else {
    return true;
  }
};

/*

  Retrieve customer payment method customer ID.

*/

type RetrievePaymentMethodByCustomerId = (
  customerId: string | number,
  email: string,
  accessToken: string
) => Promise<RechargePortalPaymentMethod | void>;

export const retrievePaymentMethodByCustomerId: RetrievePaymentMethodByCustomerId = async (
  customerId,
  email,
  accessToken
) => {
  const FXN_NAME = '[recharge: retrievePaymentMethodByCustomerId]';

  return await fetch(
    `${GATSBY_API_URL}/recharge/payment-method-retrieve?customerId=${customerId}&email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        paymentMethod: res.paymentMethod
      })();
      return res.paymentMethod as RechargePortalPaymentMethod;
    });
};

/*

  Update customer payment method customer ID.

 */

type UpdatePaymentMethodByCustomerId = (
  token: string,
  customerId: string | number,
  email: string,
  accessToken: string
) => Promise<RechargePortalPaymentMethod | void>;

export const updatePaymentMethodByCustomerId: UpdatePaymentMethodByCustomerId = async (
  token,
  customerId,
  email,
  accessToken
) => {
  const FXN_NAME = '[recharge: updatePaymentMethodByCustomerId]';
  return await fetch(
    `${GATSBY_API_URL}/recharge/payment-method-update?email=${encodeURIComponent(
      email
    )}&accessToken=${accessToken}`,
    {
      method: 'PUT',
      headers: SERVERLESS_HEADER,
      body: JSON.stringify({ token, customerId })
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      store.hydrate({
        paymentMethod: res.paymentMethod
      })();
      return res.paymentMethod as RechargePortalPaymentMethod;
    });
};

type LookupDiscount = ({
  discountCode
}: {
  discountCode: string;
}) => Promise<{ matchingDiscount?: RechargeDiscount }>;

export const lookupDiscount: LookupDiscount = async ({ discountCode }) => {
  const FXN_NAME = '[recharge: lookupDiscount]';

  return await fetch(`${GATSBY_API_URL}/recharge/discount-lookup?discountCode=${discountCode}`, {
    method: 'GET',
    headers: SERVERLESS_HEADER
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers);
};

type RetrieveShopifyOrder = ({
  rechargeChargeId,
  email
}: {
  rechargeChargeId: string;
  email: string;
}) => Promise<{ external_order_id: { ecommerce: string }; email: string; customer_id: string }>;

export const retrieveShopifyOrder: RetrieveShopifyOrder = async ({ rechargeChargeId, email }) => {
  const FXN_NAME = '[recharge: retrieveShopifyOrder]';

  return await fetch(
    `${GATSBY_API_URL}/recharge/order-retrieve-by-charge-id?chargeId=${rechargeChargeId}&email=${encodeURIComponent(
      email
    )}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers);
};

type RetrieveNextQueuedCharge = ({
  customerId,
  accessToken,
  email
}: {
  customerId: string | number;
  accessToken: string;
  email: string;
}) => Promise<RechargeCharge>;

export const retrieveNextQueuedCharge: RetrieveNextQueuedCharge = async ({
  customerId,
  accessToken,
  email
}) => {
  const FXN_NAME = '[recharge: retrieveNextQueuedCharge]';

  return await fetch(
    `${GATSBY_API_URL}/recharge/retrieve-next-queued-charge?customer_id=${customerId}&accessToken=${accessToken}&email=${encodeURIComponent(
      email
    )}`,
    {
      method: 'GET',
      headers: SERVERLESS_HEADER
    }
  )
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers);
};

/*

  Update customer profile and create event in Klaviyo after payment method update.

 */

type UpdateKlaviyoForPaymentMethodByCustomerId = (
  eventName: string,
  customerId: string | number,
  email: string,
  accessToken: string
) => Promise<any | void>;

export const updateKlaviyoForPaymentMethodByCustomerId: UpdateKlaviyoForPaymentMethodByCustomerId =
  async (eventName, customerId, email, accessToken) => {
    const FXN_NAME = '[recharge: updatePaymentMethodByCustomerId]';
    return await fetch(
      `${GATSBY_API_URL}/recharge/payment-method-update-klaviyo?email=${encodeURIComponent(
        email
      )}&accessToken=${accessToken}`,
      {
        method: 'PUT',
        headers: SERVERLESS_HEADER,
        body: JSON.stringify({ eventName, customerId })
      }
    )
      .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
      .then(redirectUnauthenticatedUsers)
      .then((res) => {
        return res;
      });
  };

interface RechargeShippingRate {
  name: string;
  price: string;
}

export const getShippingRateForItems = async (
  lineItems: ShopifyLineItem[]
): Promise<RechargeShippingRate> => {
  const body = {
    lineItems: lineItems.map((lineItem) => {
      return {
        variantId: lastPathComponent(lineItem.variant.id),
        quantity: lineItem.quantity
      };
    })
  };

  const response = await fetch(`${GATSBY_API_URL}/recharge/shipping-rates`, {
    method: 'POST',
    headers: SERVERLESS_HEADER,
    body: JSON.stringify(body)
  });

  return handleRechargeAPIError(
    response,
    'getShippingRatesForCheckout',
    {}
  ) as Promise<RechargeShippingRate>;
};
