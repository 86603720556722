import { Spacetime } from 'spacetime';
import {
  RechargeAddress,
  RechargeCheckoutResponse,
  RechargeCustomer,
  RechargePortalPaymentMethod,
  RechargeProcessedCheckout,
  RechargeSubscriptionItem,
  RechargeCheckoutPaymentPayload,
  RechargeOnetimeItem
} from 'src/types/rechargeTypes';
import { ShopifyCart } from 'src/types/shopifyTypes';
import { Param } from 'src/integrations/shopify/checkout';
import { StarterType } from 'src/constants/Bundles/options/bundleOptions';

export interface StateInterface {
  addressId: number | null;
  cart: any | null; // DEPRECATED / do not use.
  checkoutId: string | null;
  customerToken: string | null;
  email: string | null;
  existingCustomer: boolean | null;
  checkout: ShopifyCart | null;
  cartOpen: boolean | false;
  navOpen: boolean | false;
  navHide: boolean | false;
  shopOpen: boolean | false;
  catNames: Array<{ name: string }>;
  orders: any[]; // todo: update
  rechargeAddress: RechargeAddress | null;
  rechargeCustomer: RechargeCustomer | null | undefined; // undefined: not yet fetched, null: none
  rechargeCart: RechargeCheckoutResponse | null;
  paymentMethod: RechargePortalPaymentMethod | null;
  rechargeCheckoutPaymentPayload: RechargeCheckoutPaymentPayload | null;
  processedRechargeCheckout: RechargeProcessedCheckout | null;
  shipmentDate: Spacetime | null;
  region: null | string;
  activeVariant: string | null;
  shopifyCart: ShopifyCart | null;
  subscriptions: RechargeSubscriptionItem[] | undefined;
  onetimes: RechargeOnetimeItem[] | undefined;
  params: Param[];
  starterBoxType?: StarterType;
  inventory: Array<{ id: number; available: boolean }>;
  shippingRate: number | null;
}

const state: StateInterface = {
  addressId: null,
  cart: null,
  checkoutId: null,
  customerToken: null,
  email: null,
  existingCustomer: null,
  checkout: null,
  cartOpen: false,
  navOpen: false,
  navHide: false,
  shopOpen: false,
  inventory: [],
  catNames: [],
  orders: [],
  rechargeAddress: null,
  rechargeCart: null,
  rechargeCustomer: null,
  processedRechargeCheckout: null,
  paymentMethod: null,
  rechargeCheckoutPaymentPayload: null,
  region: null,
  shipmentDate: null,
  activeVariant: null,
  shopifyCart: null,
  subscriptions: [],
  onetimes: [],
  params: [],
  shippingRate: null
};

export default state;
