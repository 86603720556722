import React from 'react';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';

import { Close } from 'src/components/svgs/svgs';
import { trackAddToCart, trackRemoveFromCart } from '../../analytics/analytics';
import { Amplitude, TrackingEvent } from 'src/utils/tracking';
import { stringsToHyphenedStrings } from 'src/components/Quiz/panels/utils/quizHelper';
import { TrackingStrings } from 'src/constants/analytics';

const Price = ({
  preDiscountPrice,
  postDiscountPrice
}: {
  preDiscountPrice: string;
  postDiscountPrice: string;
}) => {
  if (preDiscountPrice === postDiscountPrice) {
    return <div className="sans-b s16 mb05">${postDiscountPrice}</div>;
  } else {
    return (
      <div className="f col jcc aie mr025">
        <div className="sans strike s16 mb0">${preDiscountPrice}</div>
        <div className="sans-b s16 mb05">${postDiscountPrice}</div>
      </div>
    );
  }
};

export const RenderLineItem = ({
  item,
  quantities,
  updateCartState,
  shopifyCart
}: {
  item: ShopifyLineItem;
  quantities: any;
  updateCartState: (arg0: {
    e: React.MouseEvent;
    lineItem: ShopifyLineItem;
    quantity: number;
    removal: boolean;
  }) => void;
  shopifyCart: ShopifyCart;
}) => {
  const isDiscountedItem = !!item?.discountAllocations.length;

  const objectInCartQuantitiesObject = quantities[`${item.variant.id}-${isDiscountedItem}`];

  const preDiscountPrice = (
    objectInCartQuantitiesObject?.quantity * parseFloat(objectInCartQuantitiesObject?.price.amount)
  ).toFixed(2);

  const postDiscountPrice = item.discountAllocations?.length
    ? (
        objectInCartQuantitiesObject?.quantity *
          parseFloat(objectInCartQuantitiesObject?.price.amount) -
        parseFloat(item.discountAllocations[0]?.allocatedAmount?.amount)
      ).toFixed(2)
    : preDiscountPrice;

  const discountMessage = item.discountAllocations[0]?.discountApplication?.description;

  const trackingProductName = stringsToHyphenedStrings(item.title)(item.variant.title)();

  return objectInCartQuantitiesObject.quantity > 0 ? (
    <div className="py1 bbw f col cart__widget-line-item">
      <div className="cart__product rel f jcb aie x" key={`cart-product-${item.id}`}>
        <div className="f jcs aic">
          <img className="mr1" src={item.smallImage ?? item.variant.image.src} alt="Product" />
          <div className="variantTitle">
            <span className="sans-b s16">{item.title}</span>
            <br />
            <span className="cooper s16">{item.variant.title}</span>
            <div className="f jcs aic mt05">
              <button
                aria-label="decrease quantity"
                className="qty__minus qty__control remove--style cursor qty__small rel cw block"
                onClick={(e) => {
                  trackRemoveFromCart(
                    {
                      name: item.title,
                      id: item.variant.sku,
                      price: parseFloat(item.variant.price.amount),
                      variant: item.variant.title
                    },
                    1,
                    shopifyCart
                  );

                  Amplitude.track(TrackingStrings.DECREASE_QUANTITY, {
                    category: TrackingStrings.CART,
                    value: trackingProductName
                  } as TrackingEvent);

                  updateCartState({
                    e,
                    lineItem: item,
                    quantity: objectInCartQuantitiesObject.quantity - 1,
                    removal: objectInCartQuantitiesObject.quantity === 1 ? true : false
                  });
                }}
              />
              <span className="m05 p05">{objectInCartQuantitiesObject.quantity}</span>
              <button
                aria-label="increase quantity"
                className="qty__plus qty__control remove--style cursor qty__small rel cw block"
                onClick={(e) => {
                  trackAddToCart(
                    {
                      name: item.title,
                      id: item.variant.sku,
                      price: parseFloat(item.variant.price.amount),
                      variant: item.variant.title
                    },
                    1,
                    shopifyCart,
                    'checkout'
                  );

                  Amplitude.track(TrackingStrings.INCREASE_QUANTITY, {
                    category: TrackingStrings.CART,
                    value: trackingProductName
                  } as TrackingEvent);

                  updateCartState({
                    e,
                    lineItem: item,
                    quantity: objectInCartQuantitiesObject.quantity + 1,
                    removal: false
                  });
                }}
              />
            </div>
          </div>
        </div>

        <Price postDiscountPrice={postDiscountPrice} preDiscountPrice={preDiscountPrice} />
        <button
          className="remove abs pt1 remove--style right top"
          aria-label="remove item"
          onClick={(e) => {
            updateCartState({
              e,
              lineItem: item,
              quantity: 0,
              removal: true
            });

            Amplitude.track(TrackingStrings.REMOVE_ITEM, {
              category: TrackingStrings.CART,
              value: trackingProductName
            } as TrackingEvent);

            trackRemoveFromCart(
              {
                name: item.title,
                id: item.variant.sku,
                price: parseFloat(item.variant.price.amount),
                variant: item.variant.title
              },
              item.quantity,
              shopifyCart
            );
          }}
        >
          <Close />
        </button>
      </div>
      {isDiscountedItem && (
        <div className="cblue50 p0 x ar sans pr025 italic">{discountMessage}</div>
      )}
    </div>
  ) : null;
};
