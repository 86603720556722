import shop from 'shopify-buy';
import { ShopifyProduct, ShopifyVariant } from 'src/types/shopifyTypes';
import { handleRechargeAPIError } from './recharge/errorHandling';
import { redirectUnauthenticatedUsers } from './recharge';

const GATSBY_API_URL = process.env.GATSBY_API_URL;
const SERVERLESS_API_KEY = process.env.GATSBY_SERVERLESS_API_KEY || '';
const SERVERLESS_HEADER = {
  'X-API-KEY': SERVERLESS_API_KEY
};
export const shopifyClient = shop.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STOREFRONT_URL!,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_KEY!,
  apiVersion: process.env.GATSBY_SHOPIFY_STOREFRONT_VERSION!
});

type ShopifyProducts = (
  product_id: number | undefined
) => Promise<ShopifyProduct | ShopifyProduct[]>;

export const getShopifyProduct = async (product_id) => {
  const FXN_NAME = '[shopify: getShopifyProduct]';

  let url = `${GATSBY_API_URL}/shopify/products`;
  if (product_id) {
    url = `${url}?product_id=${product_id}`;
  }

  return await fetch(url, {
    method: 'GET',
    headers: SERVERLESS_HEADER
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res?.product ?? res?.products;
    });
};

type ShopifyProductVariant = (variant_id: number | undefined) => Promise<ShopifyVariant>;

export const getShopifyProductVariant: ShopifyProductVariant = async (variant_id) => {
  const FXN_NAME = '[shopify: getShopifyProductVariant]';

  let url = `${GATSBY_API_URL}/shopify/variant?variant_id=${variant_id}`;

  return await fetch(url, {
    method: 'GET',
    headers: SERVERLESS_HEADER
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res?.productVariant;
    });
};

type ShopifyProductVariantAvailable = (variant_id: number | string | undefined) => Promise<any>;

export const checkShopifyProductVariant: ShopifyProductVariantAvailable = async (variant_id) => {
  const FXN_NAME = '[shopify: checkShopifyProductVariant]';

  let url = `${GATSBY_API_URL}/shopify/check-variant?variant_id=${variant_id}`;

  return await fetch(url, {
    method: 'GET',
    headers: SERVERLESS_HEADER
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res;
    });
};

type ShopifyProductAvailable = (product_id: string | number | undefined) => Promise<any>;

export const checkShopifyProduct: ShopifyProductAvailable = async (product_id) => {
  const FXN_NAME = '[shopify: checkShopifyProduct]';

  let url = `${GATSBY_API_URL}/shopify/check-product?product_id=${product_id}`;

  return await fetch(url, {
    method: 'GET',
    headers: SERVERLESS_HEADER
  })
    .then((res) => handleRechargeAPIError(res, FXN_NAME, {}))
    .then(redirectUnauthenticatedUsers)
    .then((res) => {
      return res;
    });
};
