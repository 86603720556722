import React, { createRef, useEffect } from 'react';
import cookie from 'js-cookie';
import { usePicostate } from '@picostate/react';
import { CSSTransition } from 'react-transition-group';
import PageLink from 'src/components/link';
import store from 'src/state/store';

import { foodNav, companyNav } from 'src/integrations/nav';
import { Image } from './Image';

interface Props {
  navOpen?: boolean;
  customerToken?: string;
  firstName: string;
}

const closeNav = () => {
  store.hydrate({
    navOpen: false
  })();
};

export const MobileMenu = ({ pageContext }: { pageContext: any }) => {
  const [state] = usePicostate();
  const { customerToken, navOpen, firstName }: Props = state;

  const loggedIn = customerToken || cookie.get('customer_token');
  const video = createRef<HTMLVideoElement>();

  const mainNavs = [{ items: foodNav.items }];
  const mobileMenuBackgroundImage = pageContext?.misc?.mobileMenuBackgroundImage;

  useEffect(() => {
    const menuVideo = video.current;
    if (navOpen) {
      // @ts-ignore
      menuVideo?.play();
    }
    // @ts-ignore
    return () => menuVideo?.pause();
  }, [navOpen]);

  const renderNavBlock = (
    {
      route,
      text,
      className
    }: {
      route: string;
      text: string;
      className: string;
      columnClassName: string;
    },
    n: number
  ) =>
    route ? (
      <PageLink onClick={() => closeNav()} to={route ? route : '#'} className={className} key={n}>
        {text}
      </PageLink>
    ) : (
      <a href="#" onClick={() => closeNav()} className={className} key={n}>
        {text}
      </a>
    );

  return (
    <CSSTransition in={navOpen} timeout={500} classNames={'nav__mobile'}>
      <div className="nav__mobile fix x y top left al cw bcb">
        {mobileMenuBackgroundImage && (
          <Image
            className="x y nav__mobile-background"
            imageId={mobileMenuBackgroundImage.asset._ref}
          />
        )}
        <div className="nav-spacer" />
        <div className="nav__mobile-inner rel z3 cw outer px1 pb05 ">
          <div className="x ar py1 mt1 caps">
            <div className="">
              {loggedIn && firstName ? (
                <a href="/account" className="mr1 s12 ls-ss">
                  Hi, {firstName}
                </a>
              ) : (
                <a href="/account" className="mr1 caps ls1 s12">
                  Log in
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="outer x pb1 z3">
          {mainNavs.map((nav: any, i) => (
            <div className="nav__mobile-block" key={i}>
              {nav.items.map(renderNavBlock)}
            </div>
          ))}
        </div>
        <div className="outer z3 pt1">
          {[companyNav].map((nav: any, i) => (
            <div className="nav__mobile-block" key={i}>
              {nav.items.map(renderNavBlock)}
            </div>
          ))}
        </div>
        <div className="nav-spacer" />
      </div>
    </CSSTransition>
  );
};
