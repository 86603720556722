import React from 'react';

import { Close } from 'src/components/svgs/svgs';
import { ShopifyLineItem } from 'src/types/shopifyTypes';

export const RenderStarter = ({
  handleRemoval,
  subscriptionItems,
  samplerBoxOfferPrice
}: {
  handleRemoval: (e: React.MouseEvent) => void;
  subscriptionItems: ShopifyLineItem[];
  samplerBoxOfferPrice: number;
}) => {
  return (
    <div className="pt1 cart__sub rel mb05">
      <button
        aria-label="remove item"
        className="remove remove--style abs right top"
        onClick={handleRemoval}
      >
        <Close />
      </button>
      <div className="pb0">
        <h5 className="m0 p0 s16 sans-b mb05">Starter Box</h5>
        <div className="s16 cooper f col">
          {subscriptionItems.length ? (
            <div className="al s16 sans-b">${samplerBoxOfferPrice?.toFixed(2)}</div>
          ) : (
            <div className="al s16 sans-b">$35</div>
          )}
        </div>
      </div>
    </div>
  );
};
