import { SanityDiscountCode } from 'src/types/sanityTypes';
import { discountCodesByCode, starterBoxes } from 'src/integrations/sanity/queries';
import sanityClient from 'src/integrations/sanity';
import { DRY, WET } from 'src/constants/texture';

type LookupDiscountCode = ({ discountCode }: { discountCode: string }) => SanityDiscountCode[];

export const lookupSanityDiscountCode = async ({ discountCode }) => {
  let query = discountCodesByCode(discountCode);
  let data: SanityDiscountCode[] = await sanityClient?.fetch(query);
  if (!data || data?.length === 0) {
    query = discountCodesByCode('DEFAULT');
    data = await sanityClient?.fetch(query);
  }
  return data;
};

export const fetchStarterBoxDetails = async ({ starterBoxType }) => {
  const mealPlanPageFromSanity = await sanityClient?.fetch(starterBoxes);
  const starterBoxesFromSanity =
    mealPlanPageFromSanity && mealPlanPageFromSanity[0]?.modules?.starterBoxes;
  let data;
  if (starterBoxType === WET) {
    data = starterBoxesFromSanity?.filter((starter) => starter.cardTitle === 'Wet Starter');
  } else if (starterBoxType === DRY) {
    data = starterBoxesFromSanity?.filter((starter) => starter.cardTitle === 'Dry Starter');
  } else {
    data = starterBoxesFromSanity?.filter((starter) => starter.cardTitle === 'Wet & Dry Starter');
  }
  return data && data[0];
};

export const starterBoxByType = ({ starterBoxes, starterBoxType }) => {
  if (starterBoxType === WET) {
    return starterBoxes?.find((starter) => starter.cardTitle === 'Wet Starter');
  } else if (starterBoxType === DRY) {
    return starterBoxes?.find((starter) => starter.cardTitle === 'Dry Starter');
  } else {
    return starterBoxes?.find((starter) => starter.cardTitle === 'Wet & Dry Starter');
  }
};
